import * as React from "react";
import {
  AlertBanner,
  Button,
  CardBox,
  DashboardSkeleton,
  FaqPreview,
  FaqPreviewAnswer,
  LabeledValue,
  Price,
  RepaymentProgressBar,
  RepaymentProgressBarContainer,
  SidePanel,
  SimpleProgressBar,
  SummaryBox,
  SummaryDivider,
} from "@brainfinance/icash-component-library";
import { navigate } from "gatsby";
import { GatsbyLink } from "@components/gatsby-link";
import { Subheading } from "@components/typography";
import goodImg from "../../../images/proof/good.jpg";
import wrongImg from "../../../images/proof/wrong.png";
import imageBack from "@images/symbol-instant-loans-half-left.svg";

import {
  ActiveApplicationInstantLoan,
  ActiveInstantLoan,
  CollectionInstantLoan,
  InstantLoanDashboard,
  InstantLoanDashboardActiveLoan,
  ManitobaCannotReApplyInstantLoan,
  PendingDisburseInstantLoan,
  PendingSignatureInstantLoan,
  PreApprovedInstantLoan,
  WaitingForPayoffInstantLoan,
} from "./types";
import { FaqHandler } from "@containers/faq-handler";
import { SupportPanel } from "@containers/support-panel";
import {
  formatDate,
  getBigDateFormat,
  getFormatedDate,
} from "@helpers/dateFormat";
import { CollectionLoanType } from "@components/loan-history-cards/types";
import { dateFormatDay } from "@components/loan-history-cards/utilites/dateFormat";
import { UserContext } from "@helpers/user";
import { referralProgramEligibleAccesible } from "@helpers/dashboardNavigationConditions";
import { getUserSessionId } from "@helpers/auth";
import { mariaFetch } from "@helpers/maria-fetch";

export const InstantLoanBanner = (props: {
  dashboardInfo: InstantLoanDashboard;
}) => {
  const instantLoanBanner = React.useMemo(() => {
    if (props.dashboardInfo.status === "activeLoan") {
      if (props.dashboardInfo.data.length === 1) {
        const loan = props.dashboardInfo.data[0];
        if (loan.loanStatus === "active" || loan.loanStatus === "activePTP") {
          return (
            <div className="w-full grid grid-cols-5 gap-4">
              <div className="col-span-3 sm:col-span-5">
                <ActiveLoan loan={loan} displayFAQ={false} />
              </div>
              <div className="col-span-2 sm:col-span-5">
                <InstantLoanSummaryBox loan={loan} displayFAQ={false} />
                <div className="mt-[1rem]">
                  <FAQComponent />
                </div>
              </div>
            </div>
          );
        }
        if (
          loan.loanStatus === "collection" ||
          loan.loanStatus === "collectionWaitingForAgreement"
        ) {
          return (
            <div className="w-full grid grid-cols-5 gap-4">
              <div className="col-span-5">
                <Collection {...loan} />
              </div>
              <div className="col-span-3 md:hidden"></div>
              <div className="col-span-2 md:col-span-5">
                <FAQComponent />
              </div>
            </div>
          );
        }
        if (loan.loanStatus === "activeTempoPayoff") {
          return (
            <div className="w-full grid grid-cols-5 gap-4">
              <div className="col-span-5">
                <CanApply />
              </div>
              <div className="col-span-3 md:hidden"></div>
              <div className="col-span-2 md:col-span-5">
                <FAQComponent />
              </div>
            </div>
          );
        }
        if (loan.loanStatus === "waitingForPayoff") {
          return (
            <div className="w-full grid grid-cols-5 gap-4">
              <div className="col-span-5">
                <WaitingPayoff {...loan} />
              </div>
              <div className="col-span-3 md:hidden"></div>
              <div className="col-span-2 md:col-span-5">
                <FAQComponent />
              </div>
            </div>
          );
        }
      } else if (props.dashboardInfo.data.length > 1) {
        const orderLoans = props.dashboardInfo.data.sort((loanA, loanB) => {
          if (loanB.startDate == null) {
            return -1;
          }
          if (loanA.startDate == null) {
            return 1;
          }
          const dateB = new Date(loanB.startDate);
          const dateA = new Date(loanA.startDate);
          return dateA.getTime() - dateB.getTime();
        });

        const firstLoan = orderLoans[0];

        orderLoans.splice(0, 1);

        return (
          <ManyLoansComponent firstLoan={firstLoan} otherLoans={orderLoans} />
        );
      }
    } else if (props.dashboardInfo.status === "activeApplication") {
      return (
        <div className="w-full grid grid-cols-5 gap-4">
          <div className="col-span-5">
            <ActiveApplication {...props.dashboardInfo.data} />
          </div>
          <div className="col-span-3 md:col-span-5">
            <ReferAFriend />
          </div>
          <div className="col-span-2 md:col-span-5">
            <FAQComponent />
          </div>
        </div>
      );
    } else if (props.dashboardInfo.status === "blacklisted") {
      return <Blacklisted />;
    } else if (props.dashboardInfo.status === "canApply") {
      return (
        <div className="w-full grid grid-cols-5 gap-4">
          <div className="col-span-5">
            <CanApply />
          </div>
          <div className="col-span-3 md:col-span-5">
            <ReferAFriend />
          </div>
          <div className="col-span-2 md:col-span-5">
            <FAQComponent />
          </div>
        </div>
      );
    } else if (props.dashboardInfo.status === "manitobaCannotReApply") {
      return (
        <div className="w-full grid grid-cols-5 gap-4">
          <div className="col-span-5">
            <ManitobaCannotReApply {...props.dashboardInfo.data} />
          </div>
          <div className="col-span-3 md:hidden"></div>
          <div className="col-span-2 md:col-span-5">
            <FAQComponent />
          </div>
        </div>
      );
    } else if (props.dashboardInfo.status === "pendingDisburse") {
      return (
        <div className="w-full grid grid-cols-5 gap-4">
          <div className="col-span-5">
            <PendingDisburse {...props.dashboardInfo.data} />
          </div>
          <div className="col-span-3 md:hidden"></div>
          <div className="col-span-2 md:col-span-5">
            <FAQComponent />
          </div>
        </div>
      );
    } else if (props.dashboardInfo.status === "pendingSignature") {
      return (
        <div className="w-full grid grid-cols-5 gap-4">
          <div className="col-span-5">
            <PendingSignature {...props.dashboardInfo.data} />
          </div>
          <div className="col-span-3 md:col-span-5">
            <ReferAFriend />
          </div>
          <div className="col-span-2 md:col-span-5">
            <FAQComponent />
          </div>
        </div>
      );
    } else if (props.dashboardInfo.status === "preApproved") {
      return (
        <div className="w-full grid grid-cols-5 gap-4">
          <div className="col-span-5">
            <PreApproved {...props.dashboardInfo.data} />
          </div>
          <div className="col-span-3 md:col-span-5">
            <ReferAFriend />
          </div>
          <div className="col-span-2 md:col-span-5">
            <FAQComponent />
          </div>
        </div>
      );
    } else if (props.dashboardInfo.status === "provinceNotSupported") {
      return <ProvinceNotSupported />;
    }
    return <div>Error.</div>;
  }, [props.dashboardInfo]);

  return (
    <div className="contents w-full max-w-[1050px]">{instantLoanBanner}</div>
  );
};

const loanStatusConversion = (
  status:
    | "waitingForPayoff"
    | "activeTempoPayoff"
    | "collection"
    | "collectionWaitingForAgreement"
    | "active"
    | "activePTP"
) => {
  if (status === "active") {
    return "Active";
  } else if (status === "activePTP") {
    return "Active PTP";
  } else if (status === "activeTempoPayoff") {
    return "Temporary payoff";
  } else if (status === "collection") {
    return "Outstanding ⚠️ Action is needed";
  } else if (status === "collectionWaitingForAgreement") {
    return "Outstanding ⚠️ Action is needed";
  } else if (status === "waitingForPayoff") {
    return "Waiting for payoff";
  }
};

const OtherLoans = (props: { data: InstantLoanDashboardActiveLoan[] }) => {
  let loanNb = props.data.length;

  const alertBannerStatus = React.useMemo(() => {
    let alertBannerStatus: "info" | "warning" | "error" | "valid" | undefined =
      "info";
    const collectionLoan = props.data.find(
      (loan) =>
        loan.loanStatus === "collection" ||
        loan.loanStatus === "collectionWaitingForAgreement"
    );
    const activeLoan = props.data.find(
      (loan) => loan.loanStatus === "active" || loan.loanStatus === "activePTP"
    );
    const tempoLoan = props.data.find(
      (loan) =>
        loan.loanStatus === "activeTempoPayoff" ||
        loan.loanStatus === "waitingForPayoff"
    );

    if (activeLoan) {
      alertBannerStatus = "info";
    }
    if (tempoLoan) {
      alertBannerStatus = "warning";
    }
    if (collectionLoan) {
      alertBannerStatus = "error";
    }
    return alertBannerStatus;
  }, [props.data]);

  const loans = React.useMemo(() => {
    const collectionsLoans = props.data.filter(
      (loan) =>
        loan.loanStatus === "collection" ||
        loan.loanStatus === "collectionWaitingForAgreement"
    );

    const payoffLoans = props.data.filter(
      (loan) =>
        loan.loanStatus === "activeTempoPayoff" ||
        loan.loanStatus === "waitingForPayoff"
    );

    const activeLoans = props.data.filter(
      (loan) => loan.loanStatus === "active" || loan.loanStatus === "activePTP"
    );
    return [...collectionsLoans, ...payoffLoans, ...activeLoans];
  }, [props.data]);

  return (
    <AlertBanner status={alertBannerStatus} className="w-full">
      <h3 className="global--title mb-[24px]">Other loans</h3>
      <p className="text-interface-300 global--caption">
        You currently have {loanNb} more open instant loan. To get more
        information check the link below:
      </p>
      <ul className="list-inside mt-[11px]">
        {loans.map((loan, key: number) => (
          <li key={key} className="text-interface-300 global--caption">
            Loan #{loan.id}; State: {loanStatusConversion(loan.loanStatus)}
          </li>
        ))}
      </ul>
      <GatsbyLink
        inheritStyle
        className="global--link inline-block mt-[14px]"
        to="/dashboard/instant-loan/loan-history"
      >
        View all loan history
      </GatsbyLink>
    </AlertBanner>
  );
};

const CanApply = () => (
  <AlertBanner status="info" className="w-full">
    <h3 className="global--title mb-[24px]">Loan repaid</h3>
    <p className="text-interface-300 global--caption mb-[33px]">
      Congratulations! Your loan has been fully repaid.
    </p>
    <Button
      appearance="primary"
      onClick={() => {
        navigate("/dashboard/application-start");
      }}
    >
      Start your Instant loan
    </Button>
  </AlertBanner>
);

const ActiveApplication = (_: ActiveApplicationInstantLoan) => (
  <AlertBanner status="info" className="w-full">
    <h3 className="global--title mb-[24px]">Instant loan</h3>
    <p className="text-interface-300 global--caption mb-[33px]">
      <b>Continue your loan application!</b>
      <br></br>
      There are only a few steps remaining to finalize your loan application!
    </p>
    <Button
      appearance="primary"
      onClick={() => {
        navigate("/dashboard/application-start");
      }}
    >
      Continue your loan application
    </Button>
  </AlertBanner>
);

const PreApproved = (_: PreApprovedInstantLoan) => (
  <AlertBanner status="info" className="w-full">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan
    </h2>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%] md:max-w-full">
      You have been pre-approved!
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%] md:max-w-full">
      This means you are one step away from being approved for your loan.
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%] md:max-w-full">
      We need to manually review the information you provided.
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%] md:max-w-full">
      Once your loan has officially been approved, you will receive an email
      with a link that bring you back to your iCash.ca account to electronically
      sign your Agreement.
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%] md:max-w-full">
      Our team is constantly revising loan loan application 7 days a week (6am -
      0am EST), so you should be receiving an answer shortly!
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%] md:max-w-full">
      You will get a response within 2 hours during the week and on weekends, it
      could go up to 4 hours. (Business hours)
      <br />
    </p>
    <p className="global--paragraph text-interface-300 max-w-[70%] md:max-w-full">
      If you have any questions, feel free to consult our{" "}
      <a
        className="text-primary hover:text-primary-emphasis font-500"
        href={"/faq"}
        target="_blank"
      >
        FAQ
      </a>
      .
    </p>
  </AlertBanner>
);

const PendingSignature = (props: PendingSignatureInstantLoan) => (
  <AlertBanner status="info" className="w-full">
    <div className="relative">
      <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
        Instant Loan
      </h2>
      <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%] md:max-w-full">
        Congratulations!
        <br />
        Your loan application has been approved! Please take a moment to read
        and understand the terms of your loan agreement. Your loan agreement
        will expire on {formatDate(new Date(props.endDate))}. Please make sure
        to sign your loan agreement before it expires on{" "}
        {formatDate(new Date(props.endDate))}.
      </p>
      <Button
        appearance="primary"
        onClick={() => {
          navigate("/dashboard/application-contract");
        }}
        className="sm:px-[13px]"
      >
        Sign the contract
      </Button>
    </div>
  </AlertBanner>
);

const PendingDisburse = (props: PendingDisburseInstantLoan) => (
  <AlertBanner status="info" className="w-full">
    <div className="relative">
      <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
        Instant Loan{" "}
        <span className="global--small-label ml-[5px]">#{props.id}</span>
      </h2>
      <p className="global--paragraph text-interface-300 max-w-[70%] md:max-w-full">
        If you have selected that you do not want to receive an e-transfer, we
        will send your funds by direct deposit. Please note that direct deposit
        are sent one time a day, business days, at 2pm and the reception of the
        funds can take up to one day.
      </p>
    </div>
  </AlertBanner>
);

const ActiveLoan = (props: {
  loan: ActiveInstantLoan;
  displayFAQ: boolean;
}) => (
  <div className="w-full">
    <div className="h-fit">
      <CardBox>
        <h2 className="global--title mb-[40px] pt-[3px]">Repayment status</h2>
        <p className="global--label text-interface-300 mb-[12px]">
          Paid amount
        </p>
        <Price
          amount={props.loan.paidAmount ?? 0}
          size="large"
          className="mb-[34px]"
        />
        <RepaymentProgressBarContainer>
          {props.loan.payments.length <= 3 ? (
            props.loan.payments
              .reverse()
              .filter(
                (payment) =>
                  payment.status === "Paid" || payment.status === "Pending"
              )
              .map((payment) => (
                <RepaymentProgressBar
                  key={payment.id}
                  amount={payment.amount}
                  isPaid={payment.status === "Paid" ? true : false}
                  paymentDate={new Date(payment.dueDate)}
                />
              ))
          ) : props.loan.payments.length > 3 ? (
            <SimpleProgressBar
              completed={
                props.loan.payments.filter(
                  (payment) => payment.status === "Paid"
                ).length
              }
              goal={props.loan.payments.length}
              unit="repayments"
            />
          ) : null}
        </RepaymentProgressBarContainer>
      </CardBox>
      {props.loan.nextPayment && (
        <CardBox>
          <h2 className="global--title mb-[40px] pt-[3px]">Payment details</h2>
          <div className="flex" key={props.loan.nextPayment?.id}>
            <div className="w-1/2">
              <p className="global--label text-interface-300 mb-[14px]">
                Next payment amount
              </p>
              <Price
                amount={props.loan.nextPayment?.amount || 0}
                size="medium"
                className="mb-[38px]"
              />
              <p className="global--label text-interface-300 mb-[14px]">
                Next payment date
              </p>
              {props.loan.nextPayment?.dueDate == null ? (
                <p className="text-[31px] font-500 leading-[34px]">
                  Not defined
                </p>
              ) : (
                getBigDateFormat(new Date(props.loan.nextPayment?.dueDate))
              )}
            </div>
            <div className="w-1/4 sm:w-1/3">
              <p className="global--label text-interface-300 mb-[14px]">
                Payment source
              </p>
              <img
                src={`data:image/png;base64, ${
                  props.loan.payments
                    .reverse()
                    .find(
                      (payment) =>
                        payment.status === "Pending" &&
                        payment.bankAccount?.bankLogo !== undefined
                    )?.bankAccount?.bankLogo ?? ""
                }`}
                alt="Bank icon"
                className="w-1/2"
              />
              <br />
              <p className="text-[14px] mt-[-8px]">
                {props.loan.nextPayment?.source}
              </p>
            </div>
          </div>
        </CardBox>
      )}
    </div>
  </div>
);

const WaitingPayoff = (props: WaitingForPayoffInstantLoan) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  return (
    <>
      <AlertBanner status="warning" className="w-full">
        <div className="relative">
          <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
            Instant Loan{" "}
            <span className="global--small-label ml-[5px]">#{props.id}</span>
          </h2>
          <p className="global--paragraph text-interface-300 mb-[12px] sm:mb-[16px] max-w-[70%] md:max-w-full">
            If you wish to reapply send us a proof of payment.
            <br />
            Your last payment has created your account and you cannot reapply?
            <br />
            Send a proof of payment for your last debited payment, 1 day after
            the payment date, to{" "}
            <a
              className="text-primary hover:text-primary-emphasis font-500"
              href={"mailto:documents@icash.ca"}
            >
              documents@icash.ca
            </a>
            .<br />
          </p>
          <p className="global--paragraph text-interface-300 max-w-[70%] md:max-w-full">
            Follow{" "}
            <span className="global--link" onClick={() => setIsOpen(true)}>
              these instructions
            </span>{" "}
            carefully to reapply.
          </p>
        </div>
      </AlertBanner>
      <SidePanel active={isOpen} onOpen={handleOpen} onClose={handleClose}>
        <Subheading>Follow these instructions to reapply</Subheading>
        <p className="global--paragraph mt-[44px]">
          Here's what a GOOD proof of payment looks like:
          <br />
          <b>Good proof of payment:</b>
          <br />
          <br />
          <a href={goodImg} target="_blank">
            <img
              src={goodImg}
              alt="Good"
              className="border-[2px] border-[#52a215]"
            />
          </a>
          <br />
          Make sure that:
          <ul className="list-none">
            <li>
              - We can see the iCash payment, the date of the payment, and your
              account balance IMMEDIATELY after the iCash loan payment
              withdrawal (NOT your current balance).
            </li>
            <li>
              - The document is added as an attachment and not copy-pasted in
              your email.
            </li>
            <li>
              - Send a proof of payment that respects the following criteria or
              it will be refused and you will have to wait the 4 business days
              to reapply.
            </li>
          </ul>
          <br />
          <b>BAD PROOF OF PAYMENT:</b>
          <br />
          <br />
          <a href={wrongImg} target="_blank">
            <img
              src={wrongImg}
              alt="Wrong"
              className="w-1/2 mx-auto border-[2px] border-[#f34747] sm:w-full"
            />
          </a>
          <br />
          <br />
        </p>
      </SidePanel>
    </>
  );
};

const ManitobaCannotReApply = (_: ManitobaCannotReApplyInstantLoan) => (
  <AlertBanner status="info" className="w-full">
    <div className="relative">
      <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
        Instant Loan
      </h2>
      <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%] md:max-w-full">
        Sorry, you have to wait to apply again.
        <br />
        Thank you for making your last payment. If you don't see the "reapply"
        button right away, it is because you need to wait 8 days from the date
        of your last payment before you can reapply, in accordance with Manitoba
        regulations.
        <br />
      </p>
      <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%] md:max-w-full">
        You will be able to start your loan application 8 days after your last
        payment date.
      </p>
      <p className="global--small italic max-w-[70%] md:max-w-full">
        *If for any reason your payment will be returned, please let us know by
        email us at support us as{" "}
        <a
          className="text-primary hover:text-primary-emphasis font-500"
          href={"mailto:support@icash.ca"}
        >
          support@icash.ca
        </a>
      </p>
    </div>
  </AlertBanner>
);

const Collection = (props: CollectionInstantLoan) => (
  <AlertBanner status="error" className="w-full">
    <div className="relative whitespace-pre-line">
      <div>
        <div className="w-3/5 md:w-full">
          <h2 className="global--title mb-[18px]">Required action ⚠️</h2>
          <p className="global--paragraph text-interface-300">
            As of today, your instant loan{" "}
            <span className="font-600">#{props.id}</span> has an outstanding
            balance of{" "}
            {props.collectionDetails.dueTotal?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
            .{"\n"}
            This amount is only valid until 0:00 EST on{" "}
            {dateFormatDay(props.collectionDetails.validUntil ?? undefined)}.
            {"\n"}
            To save time, money, and avoid collection communications, please
            take an action now.
          </p>
        </div>
        <div className="w-4/5 md:w-full">
          <div className="">
            <h3 className="global--title mb-[22px] mt-[22px]">
              Outstanding balance details
            </h3>
            <div className="flex flex-col">
              <div className="grid grid-cols-4 w-[80%] sm:w-[60%] sm:grid-cols-1 gap-4 mb-[22px]">
                <div className="">
                  <p className="global--label text-interface-300 mb-[10px]">
                    Of past due amount
                  </p>
                  <p className="text-[15px] font-700">
                    {props.collectionDetails.dueAmount?.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                      }
                    )}
                  </p>
                </div>
                <div className="">
                  <p className="global--label text-interface-300 mb-[10px]">
                    Of late interest
                  </p>
                  <p className="text-[15px] font-700">
                    {props.collectionDetails.dueLateInterest?.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                      }
                    )}
                  </p>
                </div>
                <div className="">
                  <p className="global--label text-interface-300 mb-[10px]">
                    Of NSF fees
                  </p>
                  <p className="text-[15px] font-700">
                    {props.collectionDetails.dueNsf?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                </div>
                <div className="sm:border-t-[0.5px] border-[#D2D2D2]">
                  <p className="global--label text-interface-300 mb-[10px] sm:mt-[10px]">
                    Total balance
                  </p>
                  <p className="text-[15px] font-700">
                    {props.collectionDetails.dueTotal?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-4/5">
          <p className="global--paragraph text-interface-300">
            Please, contact us to fix this issue.
          </p>
          <div className="mt-[22px]">
            <Button
              appearance="primary"
              onClick={() =>
                window.open(`mailto:${props.collectionDetails.contactEmail}`)
              }
            >
              Contact us now
            </Button>
          </div>
        </div>
      </div>
    </div>
  </AlertBanner>
);

const WaitingForNewAgreement = (props: CollectionLoanType) => (
  <AlertBanner status="error" className="w-full">
    <div className="relative">
      <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
        Instant Loan{" "}
        <span className="global--small-label ml-[5px]">#{props.id}</span>
      </h2>
      <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%] md:max-w-full">
        Sorry, you have to wait to apply again
        <br />
        You already have an open loan with us!
        <br />
        We will be happy to provide you with another as soon as you have repaid
        your existing loan in full!
      </p>
      <div>
        <h2 className="global--title mb-[18px]">Missed payment ⚠️</h2>
        <p className="global--caption text-interface-300 mb-[22px] max-w-[70%] md:max-w-full">
          As of today, your personal loan has an outstanding balance of{" "}
          <b>${props.collectionDetails?.dueTotal ?? 0}</b>.<br />
          This amount is only valid until{" "}
          {getFormatedDate(
            new Date(props.collectionDetails?.validUntil ?? ""),
            "short",
            "long",
            true
          )}
          .<br />
          To save time, money, and avoid collection communications, please take
          an action now.
        </p>
        <h3 className="global--title mb-[22px]">Outstanding balance details</h3>
        <div className="flex justify-start border-b-[1px] border-b-[#D2D2D2] mb-[33px] max-w-[70%] md:max-w-full">
          <div className="w-1/4">
            <p className="global--label text-interface-300 mb-[10px]">
              Of past due amount
            </p>
            <p className="text-[15px] font-700 mb-[28px]">
              ${props.collectionDetails?.dueAmount}
            </p>
          </div>
          <div className="w-1/4 relative">
            <p className="global--label text-interface-300 mb-[10px]">
              Of late interest
            </p>
            <p className="text-[15px] font-700">
              ${props.collectionDetails?.dueLateInterest}
            </p>
          </div>
          <div className="w-1/4 relative">
            <p className="global--label text-interface-300 mb-[10px]">
              Of NSF fees
            </p>
            <p className="text-[15px] font-700 mb-[28px]">
              ${props.collectionDetails?.dueNsf}
            </p>
            <span className="h-[45px] w-[1px] bg-[#D2D2D2] absolute right-[58px] top-0" />
          </div>
          <div className="w-1/4 relative">
            <p className="global--label text-interface-300 mb-[10px]">
              Total balance
            </p>
            <p className="text-[15px] font-700">
              ${props.collectionDetails?.dueTotal}
            </p>
          </div>
        </div>
        <p className="text-interface-300 global--caption mb-[22px]">
          Please, contact us to fix this issue.
        </p>
        <Button
          appearance="primary"
          onClick={() => {
            navigate("/dashboard/repayment-plan", {
              state: {
                loanId: props.id,
              },
            });
          }}
          className="sm:px-[13px]"
        >
          Start your agreement
        </Button>
      </div>
    </div>
  </AlertBanner>
);

const ProvinceNotSupported = () => (
  <AlertBanner status="info" className="w-full">
    <div className="relative">
      <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
        Instant Loan
      </h2>
      <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%] md:max-w-full">
        Coming soon!
        <br />
        Unfortunately, iCash is not available in your province yet!
        <br />
        We are working on it and we will inform you as soon as iCash becomes
        available in your province!
      </p>
    </div>
  </AlertBanner>
);

const Blacklisted = () => (
  <AlertBanner status="info" className="w-full">
    <div className="relative">
      <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
        Instant Loan
      </h2>
      <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%] md:max-w-full">
        Unfortunately we cannot offer you a loan at this time, but you can still
        access the information of your previous loans.
      </p>
    </div>
  </AlertBanner>
);

const InstantLoanSummaryBox = (props: {
  loan: ActiveInstantLoan;
  displayFAQ: boolean;
}) => {
  return (
    <SummaryBox
      className={
        "relative w-full max-w-none bg-[length:200px_50%] lg:bg-[length:120px_50%] sm:bg-[length:180px_50%]"
      }
      style={{
        backgroundImage: `url(${imageBack})`,
        backgroundPosition: "100% 53px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <h4 className="summary-box--title">Loan</h4>
      <span className="global--small-label ml-[11px] !font-400">
        #{props.loan.id}
      </span>
      <div className="space-y-[34px]">
        <LabeledValue label="Repayment">
          {props.loan.payments.length}
        </LabeledValue>
        <LabeledValue label="">
          <GatsbyLink
            to="/dashboard/loyalty-program"
            inheritStyle
            className="global--link inline-block text-[14px] leading-[20px] tracking-[-0.3px] mb-[22px]"
          >
            Elite Cashback
          </GatsbyLink>
          <Price amount={0.05 * props.loan.amount || 0} size="medium" />
        </LabeledValue>
      </div>
      <SummaryDivider />
      <div className="align-text-bottom">
        <LabeledValue label="Loan amount" size="large">
          <Price amount={props.loan.amount || 0} size="large" />
        </LabeledValue>
      </div>
    </SummaryBox>
  );
};

const FAQComponent = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <SummaryBox className="side w-full max-w-none">
      <FaqHandler
        section="instantLoan"
        onFetchFaq={(faqInfo) => (
          <FaqPreview>
            <div className="pb-[1.5rem]">
              <h2 className="global--heading">FAQ</h2>
            </div>
            {faqInfo.items.map((item, ndx) => (
              <FaqPreviewAnswer key={ndx} question={item.question}>
                {item.answer}
              </FaqPreviewAnswer>
            ))}
            <p className="global--caption pt-[2rem]">
              <a href="/faq" className="global--link" target="_blank">
                Read our full FAQ
              </a>{" "}
              for more information.
              <br />
              <span className="text-interface-300">
                Have more questions?
              </span>{" "}
              <a onClick={handleOpen} className="global--link" target="_blank">
                Contact us
              </a>
              <SupportPanel
                isOpen={isOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
              />
            </p>
          </FaqPreview>
        )}
      />
    </SummaryBox>
  );
};

const ReferAFriend = () => {
  const { userData } = React.useContext(UserContext);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [referralData, setReferralData] =
    React.useState<{
      referredFriendCashback: number;
      amountEarnedPerReferral: number;
    }>();
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    if (!referralProgramEligibleAccesible(userData)) {
      return;
    }

    mariaFetch("/ambassador", {
      method: "GET",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserSessionId()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.error.message);
        }
        setReferralData(data.result);
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, []);

  if (!referralProgramEligibleAccesible(userData)) {
    return <div></div>;
  }

  if (loading) {
    return (
      <CardBox className="overflow-hidden min-h-[266px] sm:min-h-[140px]">
        <DashboardSkeleton />
      </CardBox>
    );
  }

  if (error || !referralData) {
    return (
      <AlertBanner status="error">{error ?? "An error occurred"}</AlertBanner>
    );
  }

  return (
    <CardBox className="overflow-hidden min-h-[266px] sm:min-h-[140px]">
      <div className="relative">
        <h3 className="global--title mb-[24px]">Refer friends</h3>
        <p className="global--caption mb-[28px] sm:mb-[16px]">
          When you refer your friends to iCash, they'll also receive a bonus.
        </p>
        <div className="flex mb-[44px]">
          <LabeledValue label="You receive" className="w-1/2">
            <Price
              amount={referralData.amountEarnedPerReferral}
              integer
              size="medium"
              className="inline-block"
            />
          </LabeledValue>
          <LabeledValue label="Friends receive" className="w-1/2">
            <Price
              amount={referralData.referredFriendCashback}
              integer
              size="medium"
              className="inline-block"
            />
          </LabeledValue>
        </div>
        <p className="global--caption mb-[28px] sm:mb-[16px]">
          Receive at ${referralData.amountEarnedPerReferral} for each friend you
          refer. Your referral will receive $
          {referralData.referredFriendCashback} in cashback!
        </p>
        <Button
          appearance="primary"
          onClick={() => navigate("/dashboard/referral-program")}
          className="sm:px-[13px] mr-[22px]"
        >
          Refer a friend - Receive {referralData.amountEarnedPerReferral}$
        </Button>
      </div>
    </CardBox>
  );
};

const ManyLoansComponent = (props: {
  firstLoan: InstantLoanDashboardActiveLoan;
  otherLoans: InstantLoanDashboardActiveLoan[];
}) => {
  return (
    <div className="w-full grid grid-cols-5 gap-4">
      {props.firstLoan.loanStatus === "collection" ||
      props.firstLoan.loanStatus === "collectionWaitingForAgreement" ? (
        <div className="col-span-5">
          <Collection {...props.firstLoan} key={props.firstLoan.id} />
        </div>
      ) : null}
      {props.firstLoan.loanStatus === "waitingForPayoff" ? (
        <div className="col-span-5">
          <WaitingPayoff {...props.firstLoan} key={props.firstLoan.id} />
        </div>
      ) : null}
      {props.otherLoans.length > 0 ? (
        <div className="col-span-5">
          <OtherLoans key={"custom-other-loans"} data={props.otherLoans} />
        </div>
      ) : null}
      {props.firstLoan.loanStatus === "active" ||
      props.firstLoan.loanStatus === "activePTP" ? (
        <div className="w-full col-span-5 grid grid-cols-5 gap-4">
          <div className="col-span-3 sm:col-span-5">
            <ActiveLoan loan={props.firstLoan} displayFAQ={false} />
          </div>
          <div className="col-span-2 sm:col-span-5">
            <InstantLoanSummaryBox loan={props.firstLoan} displayFAQ={false} />
            <div className="mt-[1rem]">
              <FAQComponent />
            </div>
          </div>
        </div>
      ) : null}
      {props.firstLoan.loanStatus === "collection" ||
      props.firstLoan.loanStatus === "collectionWaitingForAgreement" ||
      props.firstLoan.loanStatus === "waitingForPayoff" ? (
        <>
          <div className="col-span-3 md:hidden"></div>
          <div className="col-span-2 md:col-span-5">
            <FAQComponent />
          </div>
        </>
      ) : null}
    </div>
  );
};
