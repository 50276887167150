export const provinceDict: { [key: string]: string } = {
  QC: "Quebec",
  BC: "British Columbia",
  ON: "Ontario",
  NS: "Nova Scotia",
  NB: "New Brunswick",
  MB: "Manitoba",
  AB: "Alberta",
  PE: "Prince Edward Island",
  SK: "Saskatchewan",
  NL: "Newfoundland and Labrador",
  NU: "Nunavut",
  NT: "Northwest Territories",
  YT: "Yukon",
};
