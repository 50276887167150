import * as React from "react";
import {
  AlertBanner,
  CardBox,
  DashboardSkeleton,
} from "@brainfinance/icash-component-library";
import { InstantLoanBanner } from "./instant-loan-banner";
import { getUserSessionId } from "@helpers/auth";
import { InstantLoanDashboard } from "./types";
import { mariaFetch } from "@helpers/maria-fetch";

export const InstantLoanActive = () => {
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [instantActiveLoan, setInstantActiveLoan] =
    React.useState<InstantLoanDashboard>();

  React.useEffect(() => {
    const onFetchData = async () => {
      try {
        const response = await mariaFetch("/user/dashboard/instantloan", {
          headers: {
            "Application-ID":
              "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserSessionId()}`,
          },
        });

        const data = await response.json();
        if (data.error) {
          throw new Error(
            `Error code ${data.error.code}: ${data.error.message}`
          );
        }
        setInstantActiveLoan(data.result);
        setLoading(false);
      } catch (error: any) {
        setError(error.message);
        setLoading(false);
      }
    };
    onFetchData();
  }, []);

  if (loading)
    return (
      <div className="flex flex-col flex-grow-[1]">
        <CardBox>
          <DashboardSkeleton />
        </CardBox>
      </div>
    );
  if (!!error)
    return (
      <div className="flex flex-col flex-grow-[1]">
        <AlertBanner status="error">{error}</AlertBanner>
      </div>
    );

  if (!instantActiveLoan) {
    return (
      <div className="flex flex-col flex-grow-[1]">
        <AlertBanner status="error">An unknown error occured.</AlertBanner>
      </div>
    );
  }

  return <InstantLoanBanner dashboardInfo={instantActiveLoan} />;
};
