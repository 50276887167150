import { useReducer } from "react";

const marketingInitialState = {
  instantLoan: {
    value: false,
    changed: false,
  },
  personalLoan: {
    value: false,
    changed: false,
  },
  blog: {
    value: false,
    changed: false,
  },
  unsub: {
    value: false,
    changed: false,
  },
};

export type MarketingInitialActionType =
  | {
      type: "instantLoan";
      value: boolean;
    }
  | {
      type: "personalLoan";
      value: boolean;
    }
  | {
      type: "blog";
      value: boolean;
    }
  | {
      type: "unsub";
      value: boolean;
    }
  | {
      type: "unsub-all";
      value: boolean;
    }
  | {
      type: "init";
      instantLoan: boolean;
      personalLoan: boolean;
      blog: boolean;
      unsub: boolean;
    }
  | {
      type: "disable-changes";
    };

type MarketingInitialStateType = {
  instantLoan: {
    value: boolean;
    changed: boolean;
  };
  personalLoan: {
    value: boolean;
    changed: boolean;
  };
  blog: {
    value: boolean;
    changed: boolean;
  };
  unsub: {
    value: boolean;
    changed: boolean;
  };
};

const profileMarketingReducer = (
  state: MarketingInitialStateType,
  action: MarketingInitialActionType
) => {
  switch (action.type) {
    case "init":
      return {
        ...state,
        blog: { value: action.blog, changed: false },
        unsub: { value: action.unsub, changed: false },
        personalLoan: { value: action.personalLoan, changed: false },
        instantLoan: { value: action.instantLoan, changed: false },
      };
    case "disable-changes":
      return {
        ...state,
        blog: { value: state.blog.value, changed: false },
        unsub: { value: state.unsub.value, changed: false },
        personalLoan: { value: state.personalLoan.value, changed: false },
        instantLoan: { value: state.instantLoan.value, changed: false },
      };
    case "blog":
      return {
        ...state,
        blog: { value: action.value, changed: true },
        unsub: { value: false, changed: true },
      };
    case "instantLoan":
      return {
        ...state,
        instantLoan: { value: action.value, changed: true },
        unsub: { value: false, changed: true },
      };
    case "personalLoan":
      return {
        ...state,
        personalLoan: {
          value: action.value,
          changed: true,
        },
        unsub: { value: false, changed: true },
      };
    case "unsub":
      return {
        ...state,
        unsub: { value: action.value, changed: true },
      };
    case "unsub-all":
      if (action.value) {
        return {
          ...state,
          unsub: { value: action.value, changed: true },
        };
      }
      return {
        ...state,
        unsub: { value: false, changed: true },
      };
    default:
      return { ...state };
  }
};

export const useProfileMarketingReducer = () =>
  useReducer(profileMarketingReducer, marketingInitialState);
