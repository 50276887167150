import * as React from "react";
import { UserContext } from "@helpers/user";

import { DashboardContainer } from "@containers/dashboard";
import {
  Button,
  CardBox,
  CheckboxInput,
  DashboardSkeleton,
} from "@brainfinance/icash-component-library";
import { GatsbyLink } from "@components/gatsby-link";
import { navigate } from "gatsby";
import { useScreenSize } from "../../../helpers";
import { getUserSessionId } from "@helpers/auth";
import {
  MarketingInitialActionType,
  useProfileMarketingReducer,
} from "@helpers/profileMarketing";
import { mariaFetch } from "@helpers/maria-fetch";

function getMembership(nbLoans?: number) {
  if (typeof nbLoans === "number") {
    if (nbLoans >= 20) return "VIP";
    if (nbLoans >= 10) return "Prestige";
  }
  return "Elite";
}

export const UserProfile = () => {
  const { userData } = React.useContext(UserContext);
  const screenSize = useScreenSize();
  const [resetId, setResetId] = React.useState<number>(1);
  const [loadingMarketingInfo, setLoadingMarketingInfo] =
    React.useState<boolean>(true);
  const [state, dispatch] = useProfileMarketingReducer();

  const onEditEmail = () => {
    navigate("/dashboard/profile/change-email");
  };

  const subAllFetch = async () => {
    try {
      await mariaFetch(`/marketinggroup/all/subscribe`, {
        method: "POST",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
      });
    } catch (error) {}
  };

  const unsubAllFetch = async () => {
    try {
      await mariaFetch(`/marketinggroup/all/unsubscribe`, {
        method: "POST",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
      });
    } catch (error) {}
  };

  const subToGroupFetch = async (
    type: "PersonalLoan" | "InstantLoan" | "Blog"
  ) => {
    try {
      await mariaFetch(`/marketinggroup/${type}/subscribe`, {
        method: "POST",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
      });
    } catch (error) {}
  };

  const unsubToGroupFetch = async (
    type: "PersonalLoan" | "InstantLoan" | "Blog"
  ) => {
    try {
      await mariaFetch(`/marketinggroup/${type}/unsubscribe`, {
        method: "POST",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
      });
    } catch (error) {}
  };

  const checkStateChanges = () => {
    return (
      state.instantLoan.changed ||
      state.personalLoan.changed ||
      state.blog.changed ||
      state.unsub.changed
    );
  };

  const onSaveChange = async () => {
    dispatch({ type: "disable-changes" });

    if (state.instantLoan.changed) {
      if (state.instantLoan.value) {
        await subToGroupFetch("InstantLoan");
      } else {
        await unsubToGroupFetch("InstantLoan");
      }
    }

    if (state.personalLoan.changed) {
      if (state.personalLoan.value) {
        await subToGroupFetch("PersonalLoan");
      } else {
        await unsubToGroupFetch("PersonalLoan");
      }
    }
    if (state.blog.changed) {
      if (state.blog.value) {
        await subToGroupFetch("Blog");
      } else {
        await unsubToGroupFetch("Blog");
      }
    }

    if (state.unsub.changed) {
      if (state.unsub.value) {
        await unsubAllFetch();
      } else {
        await subAllFetch();
      }
    }
  };

  const onGetMarketingGroupInfo = async () => {
    try {
      const response = await mariaFetch("/marketinggroup", {
        method: "GET",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
      });

      if (response.status === 200) {
        const data = (await response.json()) as {
          result: {
            subscribedAll: boolean;
            groups: {
              subscribed: boolean;
              type: "PersonalLoan" | "InstantLoan" | "Blog";
            }[];
          };
        };

        let obj: MarketingInitialActionType = {
          type: "init",
          unsub: !data.result.subscribedAll,
          blog: false,
          instantLoan: false,
          personalLoan: false,
        };

        for (let i = 0; i < data.result.groups.length; i++) {
          const group = data.result.groups[i];
          if (group.type === "Blog") {
            obj.blog = group.subscribed;
          } else if (group.type === "InstantLoan") {
            obj.instantLoan = group.subscribed;
          } else if (group.type === "PersonalLoan") {
            obj.personalLoan = group.subscribed;
          }
        }

        dispatch({ ...obj });

        setLoadingMarketingInfo(false);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    onGetMarketingGroupInfo();
  }, []);

  return (
    <>
      <DashboardContainer>
        <div className="w-full max-w-[1138px] p-[44px] md:p-[22px] sm:p-[11px]">
          <div className="sm:px-[11px]">
            <h1 className="global--subheading mb-[66px] text-interface-500 sm:text-[37px] sm:leading-[50px] sm:mb-[22px]">
              Profile
            </h1>
          </div>
          <div className="max-w-[640px] mb-0 text-interface-500">
            <CardBox>
              <div className="flex flex-1 flex-col">
                <h2 className="global--title sm:text-[17px] mb-[42px] sm:mb-[22px] sm:leading-[28px]">
                  Personal informations
                </h2>
                <div className="flex w-full sm:flex-col">
                  <div className="w-1/2 sm:w-full">
                    <p className="global--label mb-[7px] text-[#6F7375]">
                      Name
                    </p>
                    <p className="text-[15px] font-700 mb-[44px] leading-[17px] tracking-[-0.28px] sm:mb-[22px]">
                      {userData.firstName} {userData.lastName}
                    </p>
                  </div>
                  <div className="w-1/2 sm:w-full">
                    <p className="global--label mb-[7px] text-[#6F7375]">
                      Email
                    </p>
                    <p className="text-[15px] font-700 mb-[44px] leading-[17px] tracking-[-0.28px] flex sm:mb-[22px] sm:block">
                      <div className=" text-ellipsis overflow-hidden inline-block whitespace-nowrap max-w-[calc(100%_-_42px)] sm:block sm:mb-[11px] sm:max-w-full">
                        {userData.email}
                      </div>
                      {screenSize === "sm" ? (
                        <Button appearance="tertiary" onClick={onEditEmail}>
                          Change
                        </Button>
                      ) : (
                        <span
                          onClick={onEditEmail}
                          className="global--link ml-[16px] font-500"
                        >
                          Edit
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="global--caption text-[#6F7375] sm:text-[13px] sm:leading-[18px] sm:tracking-[-0.15px]">
                    Learn how we manage your privacy{" "}
                    <a
                      className="global--link"
                      href="/privacy-policy"
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </p>
                </div>
              </div>
            </CardBox>
            <CardBox>
              <div className="text-interface-500">
                <h2 className="global--title sm:text-[17px] mb-[42px] sm:mb-[22px] sm:leading-[28px]">
                  Membership
                </h2>
                <div className="flex w-full mb-[32px] sm:mb-[22px]">
                  <div className="w-1/2">
                    <p className="global--label text-[#6F7375] mb-[7px]">
                      Status
                    </p>
                    <p className="text-[31px] leading-[34px] tracking-[-0.77px] font-500 sm:text-[26px] sm:leading-[29px] sm:tracking-[-0.5px]">
                      {getMembership(userData.nbLoans)}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="global--caption text-[#6F7375] sm:text-[13px] sm:leading-[18px] sm:tracking-[-0.15px]">
                    Learn how more about your benefits{" "}
                    <GatsbyLink
                      className="global--link"
                      to="/dashboard/loyalty-program"
                    >
                      here
                    </GatsbyLink>
                    .
                  </p>
                </div>
              </div>
            </CardBox>
            <CardBox>
              {loadingMarketingInfo === true ? (
                <DashboardSkeleton />
              ) : (
                <div
                  key={"container-" + resetId}
                  className="text-interface-500"
                >
                  <h2 className="global--title sm:text-[17px]">
                    Email preferences
                  </h2>
                  <div className="flex w-full flex-col mt-[7px]">
                    <div className="w-4/5">
                      <p className="global--label text-xs text-[#6F7375] mb-[7px] text-[9px]">
                        Help us improve your experience with iCASH through
                        better communication. Please adjust your email
                        preferences below, and we will refine our letters to you
                        accordingly. We'll only contact you when it's worth your
                        while. Note: This will not affect your transactional
                        emails. If you decide to unsubscribe, please keep in
                        mind that it may take up to 2 days to process your
                        request.
                      </p>
                      <CheckboxInput
                        onChange={(value) => {
                          if (value !== undefined) {
                            dispatch({ type: "instantLoan", value: value });
                            setResetId(resetId + 1);
                          }
                        }}
                        disabled={state.unsub.value}
                        value={
                          state.unsub.value === true
                            ? false
                            : state.instantLoan.value
                        }
                      >
                        <div>
                          <p className="global--label text-[#626D77]">
                            Instant Loan product updates
                          </p>
                          <p className="global--label text-[9px] text-[#626D77]">
                            Updates about Finabanx's Instant Loan product.
                          </p>
                        </div>
                      </CheckboxInput>
                      <CheckboxInput
                        onChange={(value) => {
                          if (value !== undefined) {
                            dispatch({ type: "personalLoan", value: value });
                            setResetId(resetId + 1);
                          }
                        }}
                        disabled={state.unsub.value}
                        value={
                          state.unsub.value === true
                            ? false
                            : state.personalLoan.value
                        }
                      >
                        <div>
                          <p className="global--label text-[#626D77]">
                            Personal Loan product updates
                          </p>
                          <p className="global--label text-[9px] text-[#626D77]">
                            Updates about Finabanx's Personal Loan product.
                          </p>
                        </div>
                      </CheckboxInput>
                      <CheckboxInput
                        onChange={(value) => {
                          if (value !== undefined) {
                            dispatch({ type: "blog", value: value });
                            setResetId(resetId + 1);
                          }
                        }}
                        disabled={state.unsub.value}
                        value={
                          state.unsub.value === true ? false : state.blog.value
                        }
                      >
                        <div>
                          <p className="global--label text-[#626D77]">Blog</p>
                          <p className="global--label text-[9px] text-[#626D77]">
                            A newsletter that includes fun tips on all things
                            writing.
                          </p>
                        </div>
                      </CheckboxInput>
                      <div className="w-full h-[1px] bg-[#D8D8D8]"></div>
                      <CheckboxInput
                        onChange={(value) => {
                          if (value !== undefined) {
                            dispatch({ type: "unsub-all", value });
                            setResetId(resetId + 1);
                          }
                        }}
                        value={state.unsub.value}
                      >
                        <div>
                          <p className="global--label text-[#626D77]">
                            Unsubscribe from all
                          </p>
                        </div>
                      </CheckboxInput>
                    </div>
                    <div className="w-2/6">
                      <Button
                        appearance="primary"
                        disabled={!checkStateChanges()}
                        onClick={onSaveChange}
                      >
                        Save changes
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </CardBox>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};
