import * as React from "react";
import {
  AlertBanner,
  CardBox,
  DashboardSkeleton,
} from "@brainfinance/icash-component-library";
import { GatsbyLink } from "@components/gatsby-link";
import { getUserSessionId } from "@helpers/auth";
import { mariaFetch } from "@helpers/maria-fetch";

type SettingType = {
  accountNumber: string;
  bankName: string;
  bankLogo: string;
  accountType: string;
  bankBranchNumber: string;
  bankInstitutionNumber: string;
};

export const InstantLoanSettings = () => {
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [settings, setSettings] = React.useState<SettingType>();

  const fetchbankAccount = async () => {
    try {
      const res = await mariaFetch("/user/bankaccount", {
        method: "GET",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
      });

      const data: any = await res.json();

      if (data.error) {
        throw new Error(`Error code ${data.error.code}: ${data.error.message}`);
      }

      console.log(data.result);
      setSettings(data.result);
    } catch (error: any) {
      setError(error?.message ?? "");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchbankAccount();
  }, []);

  if (loading)
    return (
      <div className="flex flex-col flex-grow-[1]">
        <CardBox>
          <DashboardSkeleton />
        </CardBox>
      </div>
    );
  if (!!error || !settings)
    return (
      <div className="flex flex-col flex-grow-[1]">
        <AlertBanner status="error">{error}</AlertBanner>
      </div>
    );

  return (
    <div className="flex flex-col flex-grow-[1]">
      <CardBox className="flex-[2_0_48%] last:max-w-[55%] mb-0 sm:!max-w-full">
        <h2 className="global--title mb-[40px] pt-[3px]">Payment method</h2>
        <div className="flex justify-between">
          <div className="w-1/4">
            <div className="w-full relative">
              <p className="global--label text-interface-300 mb-[10px]">Name</p>
              <p className="text-[15px] font-700 mb-[22px]">
                {settings.bankName}
              </p>
            </div>
            <p className="global--label text-interface-300 mb-[10px]">
              Branch number
            </p>
            <p className="text-[15px] font-700">{settings.bankBranchNumber}</p>
          </div>
          <div className="w-1/4 relative">
            <div className="w-full relative">
              <p className="global--label text-interface-300 mb-[10px]">
                Number
              </p>
              <p className="text-[15px] font-700 mb-[22px]">
                {settings.bankInstitutionNumber}
              </p>
            </div>
            <p className="global--label text-interface-300 mb-[10px]">
              Account number
            </p>
            <p className="text-[15px] font-700">{settings.accountNumber}</p>
          </div>
          <div className="w-1/4">
            <img
              src={`data:image/png;base64, ${settings.bankLogo}`}
              alt="Bank Logo"
            />
          </div>
        </div>
        <div className="w-full border-t-[1px] border-[#D2D2D2] mt-[22px] pt-[22px]">
          <div className="flex flex-1 flex-row">
            <p className="global--label text-interface-300 mb-[10px]">
              Learn how to change the bank account{" "}
              <a
                href={"/faq"}
                target="_blank"
                className="text-primary hover:text-primary-emphasis font-500 text-[14px] mb-[8px]"
              >
                here
              </a>
            </p>
          </div>
        </div>
      </CardBox>
    </div>
  );
};
