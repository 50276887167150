import * as React from "react";
import {
  CardBox,
  Price,
  AlertBanner,
  SummaryBox,
  FaqPreview,
  FaqPreviewAnswer,
  LabeledValue,
  SummaryDivider,
  Button,
  CashbackProgressBar,
  Tabs,
  Tab,
  SidePanel,
} from "@brainfinance/icash-component-library";
import { GatsbyLink } from "@components/gatsby-link";
import { UserContext } from "@helpers/user";
import { navigate } from "gatsby";
import { SupportPanel } from "@containers/support-panel";
import { CashbackHistoryTabContent } from "./cashback/history-tab-content";
import { Subheading } from "@components/typography";
import { getUserSessionId } from "@helpers/auth";
import { FaqHandler } from "@containers/faq-handler";
import { slugify } from "@helpers/slugify";

// Images
import imageCashback from "@images/symbol-cashback.svg";
import { mariaFetch } from "@helpers/maria-fetch";

const summaryArgs = {
  title: "Loan",
  className: "relative w-full sm:max-w-full",
  style: {
    backgroundImage: `url(${imageCashback})`,
    backgroundPosition: "100% 20px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "170px auto",
  },
};

const membershipRate = {
  Elite: 5,
  Prestige: 10,
  VIP: 20,
};

function getMembershipRate(membershipStatus: any) {
  if (typeof membershipStatus === "string") {
    switch (membershipStatus) {
      case "Prestige":
        return membershipRate.Prestige;
      case "VIP":
        return membershipRate.VIP;
      default:
        break;
    }
  }
  return membershipRate.Elite;
}

const sortTransactions = (transactions: Record<string, any[]>) => {
  const { available, pending, pendingPayment, paid, expired } = transactions;
  return { available, pending, pendingPayment, paid, expired };
};

const isEmptyObjOfArrays = (obj?: Record<string, any[]>) => {
  if (!obj) return true;
  return Object.keys(obj).filter((key) => obj[key].length).length === 0;
};

export const InstantLoanCashback = () => {
  const [cashbackHistory, setCashbackHistory] = React.useState<any>({});
  const [nextMembership, setNextMembership] = React.useState<string>();
  const [totalEarned, setTotalEarned] = React.useState<number>();
  const [selectedTabId, setSelectedTabId] = React.useState<string>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  React.useEffect(() => {
    mariaFetch("/cashback/history", {
      method: "GET",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserSessionId()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const history = {
          ...data.result,
          transactions: sortTransactions(data.result.transactions),
        };
        setCashbackHistory(history);
        return history.transactions;
      })
      .then((transactions) => {
        for (const key in transactions) {
          if (transactions[key].length > 0) {
            setSelectedTabId(key);
            return;
          }
        }
      });

    if (cashbackHistory.nbPaidLoans < 10) {
      setNextMembership("Prestige");
    } else if (cashbackHistory.nbPaidLoans < 20) {
      setNextMembership("VIP");
    }

    setTotalEarned(
      cashbackHistory.total?.pendingPayment +
        cashbackHistory.total?.available +
        cashbackHistory.total?.paid
    );
  }, []);

  const selectedTabContent = React.useMemo(() => {
    if (cashbackHistory.transactions && selectedTabId)
      return (
        <CashbackHistoryTabContent
          loans={cashbackHistory.transactions[selectedTabId]}
        ></CashbackHistoryTabContent>
      );
    return null;
  }, [selectedTabId, cashbackHistory]);

  return (
    <>
      {cashbackHistory.total?.available > 0 ? (
        <div className="w-full max-w-[1050px]">
          <AlertBanner status="info">
            <h3 className="global--title mb-[24px]">
              You've earned Cashback! 💵
            </h3>
            <p className="text-interface-300 global--caption mb-[12px]">
              Congratulations, you have earned money!
            </p>
            <p className="text-interface-300 global--caption mb-[24px]">
              You have <b>${cashbackHistory.total.available}</b> available to
              redeem in your{" "}
              <GatsbyLink to={"/dashboard/wallet"}>iCASH Wallet</GatsbyLink>.
            </p>
            <Button
              appearance="primary"
              onClick={() => navigate("/dashboard/wallet")}
            >
              Redeem my earnings
            </Button>
          </AlertBanner>
        </div>
      ) : null}
      <div className="flex gap-[22px] w-full max-w-[1050px] sm:flex-col">
        <div className="flex flex-col w-full max-w-[640px] flex-grow-[1]">
          <GetCashbackCard data={cashbackHistory} />
          <CardBox>
            <h2 className="global--title mb-[24px] pt-[3px]">Earn more</h2>
            <p className="global--label text-interface-300 mb-[24px]">
              {cashbackHistory.nbPaidLoans < 21
                ? "The more loans you take, the more cashback you receive per each one."
                : `Congratulations! You reached the top level of the Cashback program. You have earned $${totalEarned} in total.`}
            </p>
            <div className="flex items-end gap-[55px]">
              <div className="w-full">
                <p className="global--label text-interface-300 mb-[22px]">
                  Progress
                </p>
                <CashbackProgressBar
                  completedAmount={
                    cashbackHistory.nbPaidLoans > 10
                      ? cashbackHistory.nbPaidLoans - 10
                      : cashbackHistory.nbPaidLoans
                  }
                  goalAmount={cashbackHistory.nbPaidLoans < 10 ? 10 : 20}
                  unit="loans"
                />
              </div>
              <div className="w-1/6">
                <LabeledValue
                  label={
                    cashbackHistory.nbPaidLoans < 21
                      ? "Next rate"
                      : "Maximum level"
                  }
                >
                  {cashbackHistory.nbPaidLoans < 10 ? 10 : 20}%
                </LabeledValue>
              </div>
            </div>
            <p className="global--label text-interface-300 mt-[36px]">
              <span className="global--link" onClick={() => setIsOpen(true)}>
                View all benefits
              </span>{" "}
              you will get when reaching <b>{nextMembership}</b>.
            </p>
          </CardBox>
          {!isEmptyObjOfArrays(cashbackHistory.transactions) ? (
            <CardBox className="max-h-[480px] overflow-hidden">
              <h2 className="global--title mb-[24px] pt-[3px]">Loans</h2>
              <Tabs size="small">
                {cashbackHistory?.transactions &&
                  Object.keys(cashbackHistory.transactions).map((key) => {
                    if (cashbackHistory.transactions[key]?.length > 0) {
                      return (
                        <Tab
                          key={key}
                          onClick={() => setSelectedTabId(key)}
                          isSelected={selectedTabId === key}
                          className="capitalize first:ml-[24px]"
                        >
                          {key.split(/^[A-Z]*$/).join(" ")}
                        </Tab>
                      );
                    }
                  })}
              </Tabs>
              <div className="mt-[26px] max-h-[324px] overflow-auto">
                {selectedTabContent}
              </div>
            </CardBox>
          ) : null}
        </div>
        <CashbackSummaryBox data={cashbackHistory} />
      </div>
      <SidePanel active={isOpen} onOpen={handleOpen} onClose={handleClose}>
        <Subheading>How does referral program work?</Subheading>
        <p className="global--paragraph mt-[44px]">
          BECOME AN AMBASSADOR
          <br />
          Once you finish repaying your first loan with iCASH, we want to invite
          you to join our iCASH Ambassadors!
          <br />
          SHARE WITH FRIENDS & FAMILY Share your Referral Code with your friends
          and family via email, social media, or SMS.
          <br />
          <br />
          GET PAID
          <br />
          As soon as your invited referral successfully pays off their first
          loan, you receive $20 in cashback. In addition, your referral also
          receives $10 in cashback.
          <br />
          The more invitees you successfully refer, the higher the amount of
          cashback you receive per referral:
          <br />
          1-5 referrals: $20
          <br />
          6-15 referrals: $25
          <br />
          16-25 referrals: $30
          <br />
          26-35 referrals: $35
          <br />
          36-45 referrals: $40
          <br />
          46-55 referrals: $45
          <br />
          56 and more: $50
          <br />
          There is no limit to the amount of cashback you can earn through
          referrals.
          <br />
          <br />
          INVITE PEOPLE YOU KNOW
          <br />
          This program is intended for you to invite people you know such as
          your friends, family and acquaintances. The more you share your
          referral code, the greater the opportunities for you to earn cashback.
          <br />
          <br />
          GOOD TO KNOW
          <br />
          <FaqHandler
            section="instantLoan"
            onFetchFaq={(faqInfo) => (
              <ul>
                {faqInfo.items.map((faq) => (
                  <li>
                    <GatsbyLink to={`/faq/${slugify(faq.question)}`}>
                      {faq.question}
                    </GatsbyLink>
                  </li>
                ))}
              </ul>
            )}
          />
          <br />
          <br />
          SPREAD THE WORD, EARN PERKS!
          <br />
          With our new and amazing referral program, iCASH wants to thank our
          loyal customer by offering cashback for referrals! What could be
          easier? By simply spreading the news of your excellent experience with
          iCASH, we show our gratitude and return the favour with cashback for
          all!
        </p>
      </SidePanel>
    </>
  );
};

const GetCashbackCard = (props: { data: Record<string, any> }) => {
  const { userData } = React.useContext(UserContext);
  return userData.hasActiveLoan ? (
    <CardBox>
      <h2 className="global--title mb-[24px] pt-[3px]">Get Cashback 🎉</h2>
      <p className="global--label text-interface-300 mb-[12px]">
        Get <b>{getMembershipRate(props.data.membershipStatus)}%</b> Cashback
        when you finish to repay your{" "}
        <GatsbyLink to={"/dashboard/instant-loan"}>active loan</GatsbyLink>.
      </p>
      <p className="global--label text-interface-300 mb-[12px]">
        You will earn
      </p>
      <Price
        amount={props.data.total?.pending || 0}
        size="large"
        className="mb-[34px]"
      />
      <Button
        appearance="tertiary"
        onClick={() => {
          navigate("/dashboard/wallet");
        }}
      >
        Open Wallet
      </Button>
    </CardBox>
  ) : !userData.hasActiveLoan && userData.isReloan ? (
    <CardBox>
      <h2 className="global--title mb-[24px] pt-[3px]">Get Cashback 🎉</h2>
      <p className="global--label text-interface-300 mb-[12px]">
        {props.data.membershipStatus !== "VIP"
          ? "Start a new loan today to earn more money and progress to the next level of Loyalty to receive more cashback per loan."
          : "Start a new loan today to receive more cashback."}
      </p>
      <p className="global--label text-interface-300 mb-[12px]">
        Previously earned
      </p>
      <Price
        amount={props.data.total?.paid || 0}
        size="large"
        className="mb-[34px]"
      />
      <div className="flex gap-[15px]">
        {userData.contractExpirationDate !== "" ? (
          <Button
            appearance="primary"
            onClick={() => {
              navigate("/dashboard/sign-contract");
            }}
          >
            Sign the contract
          </Button>
        ) : (
          <Button
            appearance="primary"
            onClick={() => {
              navigate("/dashboard/application-start");
            }}
          >
            {!userData.hasActiveApplication
              ? "Start a loan"
              : "Continue your loan application"}
          </Button>
        )}
        <Button
          appearance="tertiary"
          onClick={() => {
            navigate("/dashboard/wallet");
          }}
        >
          Open Wallet
        </Button>
      </div>
    </CardBox>
  ) : null;
};

const CashbackSummaryBox = (props: { data: Record<string, any> }) => {
  const totalEarned =
    props.data.total?.pendingPayment +
    props.data.total?.available +
    props.data.total?.paid;

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div className="flex flex-col gap-[22px] flex-grow-[1] md:max-w-full min-w-[350px]">
      <SummaryBox {...summaryArgs}>
        <h4 className="summary-box--title">Summary</h4>
        <div className="space-y-[34px]">
          <LabeledValue label="Current rate">
            {getMembershipRate(props.data.membershipStatus)}%
          </LabeledValue>
          <LabeledValue label="Pending">
            <Price amount={props.data.total?.pending ?? 0} size="medium" />
          </LabeledValue>
        </div>
        <SummaryDivider />
        <div className="align-text-bottom">
          <LabeledValue label="Total earned" size="large">
            <Price amount={totalEarned || 0} size="large" />
          </LabeledValue>
        </div>
      </SummaryBox>
      <SummaryBox className="side sm:max-w-full">
        <FaqHandler
          section="cashback"
          onFetchFaq={(faqInfo) => (
            <FaqPreview>
              <div className="pb-[1.5rem]">
                <h2 className="global--heading">FAQ</h2>
              </div>
              {faqInfo.items.map((item, ndx) => (
                <FaqPreviewAnswer key={ndx} question={item.question}>
                  {item.answer}
                </FaqPreviewAnswer>
              ))}
              <p className="global--caption pt-[2rem]">
                <a href="/faq" className="global--link" target="_blank">
                  Read our full FAQ
                </a>{" "}
                for more information.
                <br />
                <span className="text-interface-300">
                  Have more questions?
                </span>{" "}
                <a
                  onClick={handleOpen}
                  className="global--link"
                  target="_blank"
                >
                  Contact us
                </a>
                .
                <SupportPanel
                  isOpen={isOpen}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                />
              </p>
            </FaqPreview>
          )}
        />
      </SummaryBox>
    </div>
  );
};
