import * as React from "react";
import { CollectionLoanType } from "../types";

import {
  AlertSection,
  Button,
  CardBox,
} from "@brainfinance/icash-component-library";

import { ContractDownloadLink } from "@components/contract-download/components";
import { dateFormatDay, dateToYMD } from "../utilites/dateFormat";
import { navigate } from "gatsby";

export const CollectionLoanComponent = (props: {
  loan: CollectionLoanType;
  onContactUsCallback: (
    contactEmail: string,
    contactPhoneNumber: string
  ) => void;
}) => {
  return (
    <CardBox key={props.loan.id}>
      <h2 className="global--title mb-[40px] pt-[3px]">
        Loan #{props.loan.id}
      </h2>
      <AlertSection status="error">
        <h3 className="global--title mb-[11px]">Required action ⚠️</h3>
        <p className="text-interface-300 global--caption max-w-[557px]">
          As of today, your instant loan has an outstanding balance of{" "}
          {props.loan.collectionDetails?.dueTotal?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
          .<br />
          This amount is only valid until 0:00 EST on{" "}
          {dateFormatDay(props.loan.collectionDetails?.validUntil)}
          .
          <br />
          To save time, money, and avoid collection communications, please take
          an action now.
        </p>
        <p className="text-[17px] font-700 mt-[20px] mb-[28px]">
          Outstanding balance details
        </p>
        <div className="grid grid-cols-4 sm:grid-cols-1 gap-4 mb-[33px] w-[70%] sm:w-[60%]">
          <div className="">
            <p className="global--label text-interface-300 mb-[10px]">
              Of past due amount
            </p>
            <p className="text-[15px] font-700">
              {props.loan.collectionDetails?.dueAmount?.toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                }
              )}
            </p>
          </div>
          <div className="">
            <p className="global--label text-interface-300 mb-[10px]">
              Of late interest
            </p>
            <p className="text-[15px] font-700">
              {props.loan.collectionDetails?.dueLateInterest?.toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                }
              )}
            </p>
          </div>
          <div className="border-r-[0.5px] sm:border-r-0 border-[#D2D2D2]">
            <p className="global--label text-interface-300 mb-[10px]">
              Of NSF fees
            </p>
            <p className="text-[15px] font-700">
              {props.loan.collectionDetails?.dueNsf?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
          <div className="sm:border-t-[0.5px] border-[#D2D2D2]">
            <p className="global--label text-interface-300 mb-[10px] sm:mt-[10px]">
              Total balance
            </p>
            <p className="text-[15px] font-700">
              {props.loan.collectionDetails?.dueTotal?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
        </div>
        {props.loan.loanStatus === "collectionWaitingForAgreement" && (
          <p className="global--label text-interface-300 mb-[22px]">
            Please, contact us now to fix this issue.
          </p>
        )}
        {props.loan.loanStatus === "collection" && (
          <p className="global--label mb-[22px]">
            ⚠️ Please, contact us before{" "}
            {dateFormatDay(props.loan.collectionDetails?.validUntil)} to save a
            late interest and NSF fees.
          </p>
        )}
        <Button
          size="large"
          onClick={() => {
            if (props.loan.loanStatus === "collectionWaitingForAgreement") {
              navigate("/dashboard/repayment-plan", {
                state: {
                  loanId: props.loan.id,
                },
              });
            } else {
              props.onContactUsCallback(
                props.loan.collectionDetails?.contactEmail ?? "",
                props.loan.collectionDetails?.contactPhoneNumber ?? ""
              );
            }
          }}
          appearance="primary"
        >
          {props.loan.loanStatus === "collectionWaitingForAgreement"
            ? "Start your agreement"
            : "Contact us now"}
        </Button>
      </AlertSection>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 mt-[33px]">
        <div className="grid grid-cols-2 gap-4 border-r-[0.5px] sm:border-r-0 border-[#D2D2D2]">
          <AlertSection status="error">
            <div className="w-full relative">
              <p className="global--label text-interface-300 mb-[10px]">
                State
              </p>
              <p className="text-[15px] font-700 mb-[22px]">Outstanding</p>
            </div>
          </AlertSection>
          <div>
            <p className="global--label text-interface-300 mb-[10px]">
              Loan amount
            </p>
            <p className="text-[15px] font-700 mb-[22px]">
              {props.loan.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
          <div>
            <p className="global--label text-interface-300 mb-[10px]">
              Start date
            </p>
            <p className="text-[15px] font-700">
              {dateToYMD(props.loan.startDate)}
            </p>
          </div>
          <div className="">
            <p className="global--label text-interface-300 mb-[10px]">
              End date
            </p>
            <p className="text-[15px] font-700">
              {dateToYMD(props.loan.endDate)}
            </p>
          </div>
        </div>
        {props.loan.agreementId !== undefined &&
        props.loan.padDocumentId !== undefined ? (
          <div className="sm:border-t-[0.5px] border-[#D2D2D2]">
            <p className="global--label text-interface-300 mb-[10px] sm:mt-[20px]">
              Documents
            </p>
            <ContractDownloadLink
              title="Loan contract"
              contractId={props.loan.agreementId}
            />
            <ContractDownloadLink
              title="Loan PAD"
              contractId={props.loan.padDocumentId}
            />
          </div>
        ) : null}
      </div>
    </CardBox>
  );
};
