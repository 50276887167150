import * as React from "react";
import { PartiallyClosedLoan } from "../types";

import { AlertSection, CardBox } from "@brainfinance/icash-component-library";

import { ContractDownloadLink } from "@components/contract-download/components";
import { dateFormatDay, dateToYMD } from "../utilites/dateFormat";

export const TempoLoanComponent = (props: PartiallyClosedLoan) => {
  return (
    <CardBox key={props.id}>
      <h2 className="global--title mb-[17px] pt-[3px]">Loan #{props.id}</h2>
      <div className="flex justify-start flex-col">
        <p className="global--label whitespace-pre-line font-400 text-interface-300 mb-[10px]">
          Verify in your bank account that the payment was issued. {"\n"}If not,
          please email us at:{" "}
          <a
            href="mailto:support@icash.ca"
            className="underline underline-offset-1 text-[#0033FF]"
          >
            support@icash.ca
          </a>
          .
        </p>
        {props.payment !== undefined && (
          <div className="grid grid-cols-4 sm:grid-cols-3 gap-4">
            <div className="">
              <p className="global--label text-interface-300 mb-[10px]">
                Payment amount
              </p>
              <p className="text-[15px] font-700">
                {props.payment?.amount?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }) ?? ""}
              </p>
            </div>
            <div className="">
              <p className="global--label text-interface-300 mb-[10px]">
                Payment date
              </p>
              <p className="text-[15px] font-700">
                {dateFormatDay(props.payment?.dueDate)}
              </p>
            </div>
            <div className="">
              <p className="global--label text-interface-300 mb-[10px]">
                Payment source
              </p>
              <p className="text-[15px] font-700">{props.payment?.source}</p>
            </div>
          </div>
        )}
      </div>
      <div className="h-[1px] w-full bg-[#D8D8D8] mt-[25px] mb-[18px]"></div>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
        <div className="grid grid-cols-2 gap-4 border-r-[0.5px] sm:border-r-0 border-[#D2D2D2]">
          <AlertSection status="warning">
            <div className="w-full relative">
              <p className="global--label text-interface-300 mb-[10px]">
                State
              </p>
              <p className="text-[15px] font-700">
                {props.loanStatus === "activeTempoPayoff"
                  ? "Temporary payoff"
                  : "Waiting payoff"}
              </p>
            </div>
          </AlertSection>
          <div>
            <p className="global--label text-interface-300 mb-[10px]">
              Loan amount
            </p>
            <p className="text-[15px] font-700">
              {props.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
          <div>
            <p className="global--label text-interface-300 mb-[10px]">
              Start date
            </p>
            <p className="text-[15px] font-700">{dateToYMD(props.startDate)}</p>
          </div>
          <div>
            <p className="global--label text-interface-300 mb-[10px]">
              End date
            </p>
            <p className="text-[15px] font-700">{dateToYMD(props.endDate)}</p>
          </div>
        </div>
        {props.agreementId !== undefined &&
        props.padDocumentId !== undefined ? (
          <div className="sm:border-t-[0.5px] border-[#D2D2D2]">
            <p className="global--label text-interface-300 mb-[10px] sm:mt-[20px]">
              Documents
            </p>
            <ContractDownloadLink
              title="Loan contract"
              contractId={props.agreementId}
            />
            <ContractDownloadLink
              title="Loan PAD"
              contractId={props.padDocumentId}
            />
          </div>
        ) : null}
      </div>
    </CardBox>
  );
};
