import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useMemo,
  ReactNode,
} from "react";
import { DashboardLayout } from "@components/dashboard-layout";
import {
  LoyaltyTable,
  LoyaltyTableContentType,
  SimpleProgressBar,
} from "@brainfinance/icash-component-library";
import { getUserLoyalty, USER_MEMBERSHIP_MAP } from "@helpers/getUserLoyalty";
import { UserContext } from "@helpers/user";

const TABLE_DATA: LoyaltyTableContentType[] = [
  {
    title: "Cashback earnings 💰",
    description:
      "Earn Cashback on the cost of borrowing paid on your previous loans.",
    items: [{ text: "5%" }, { text: "10%" }, { text: "70%" }],
  },
  {
    title: "Grace period 📅",
    description: "Period before starting to repay your loan.",
    items: [{ text: "5 days" }, { text: "5 days" }, { text: "5 days" }],
  },
  {
    title: "Reapply same day 👍",
    description: "Reapply the same day as your final payment",
    items: [
      {
        text: "No",
        subtext:
          "Reapply the day after your last payment after having repaid 3 loans on time",
      },
      { text: "5 days" },
      { text: "5 days" },
    ],
  },
  {
    title: "Repayments options 🥂",
    description:
      "Choose the 1 to 3 repayment option, for the same cost of borrowing. (Unique to iCASH!)",
    items: [{ text: "1 to 3" }, { text: "1 to 3" }, { text: "1 to 3" }],
  },
  {
    title: "Referral cashback 💸",
    items: [{ text: "Yes" }, { text: "Yes" }, { text: "Yes" }],
  },
  {
    title: "Fast reloan 🚀",
    items: [{ text: "Yes" }, { text: "Yes" }, { text: "Yes" }],
  },
  {
    title: "Repayments options 🥂",
    description:
      "Choose the 1 to 3 repayment option, for the same cost of borrowing. (Unique to iCASH!)",
    items: [{ text: "1 to 3" }, { text: "1 to 3" }, { text: "1 to 3" }],
  },
  {
    title: "Referral cashback 💸",
    items: [{ text: "Yes" }, { text: "Yes" }, { text: "Yes" }],
  },
  {
    title: "Fast reloan 🚀",
    items: [{ text: "Yes" }, { text: "Yes" }, { text: "Yes" }],
  },
];

export const LoyaltyProgramPage = () => {
  const { userData } = useContext(UserContext);
  const scroll = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = useCallback(() => {
    if (!scroll.current && containerRef.current) {
      const { top } = containerRef.current.getBoundingClientRect();
      if (top < -300) {
        scroll.current = setTimeout(() => {
          setIsScrolled(true);
          scroll.current = null;
        }, 100);
      } else {
        scroll.current = setTimeout(() => {
          setIsScrolled(false);
          scroll.current = null;
        }, 100);
      }
    }
  }, []);

  const userLoyalty = useMemo(
    () => getUserLoyalty(userData?.nbLoans || 0),
    [userData, userData.nbLoans]
  );
  const headerTitleClasses = useMemo(() => {
    if (isScrolled) {
      return "global--subheading !text-[21px] !leading-[24px] mb-[11px]";
    }
    return "global--subheading mb-[33px]";
  }, [isScrolled]);
  const tableHeader: ReactNode[] = useMemo(
    () => [
      <>
        <div className={headerTitleClasses}>Elite</div>
        {!isScrolled && (
          <div className="global--small">
            Once you create an account with iCASH, you will automatically be
            part of the Elite Club.
          </div>
        )}
      </>,
      <>
        <div className={headerTitleClasses}>Prestige</div>
        {!isScrolled && (
          <>
            <div className="global--small">
              Repay 10 instant loans to upgrade to the Prestige Club.
            </div>
            {userLoyalty.name === USER_MEMBERSHIP_MAP.elite && (
              <SimpleProgressBar
                className="mt-[15px]"
                completed={userLoyalty.currentProgress ?? 0}
                goal={10}
                unit="loans"
              />
            )}
          </>
        )}
      </>,
      <>
        <div className={headerTitleClasses}>VIP</div>
        {!isScrolled && (
          <>
            <div className="global--small">
              Repay 20 instant loans to upgrade to the VIP Club.
            </div>
            {userLoyalty.name === USER_MEMBERSHIP_MAP.prestige && (
              <SimpleProgressBar
                className="mt-[15px]"
                completed={userLoyalty.currentProgress ?? 0}
                goal={10}
                unit="loans"
              />
            )}
          </>
        )}
      </>,
    ],
    [isScrolled, userLoyalty]
  );

  return (
    <DashboardLayout
      // containerClass="sm:w-fit"
      userEmail={userData?.email ?? ""}
      userMembership={userLoyalty.name}
      onContainerScroll={handleScroll}
    >
      <div
        className="p-[22px] sm:p-[0px] sm:pt-[22px] relative"
        ref={containerRef}
      >
        <LoyaltyTable
          className="mb-[44px]"
          tableData={TABLE_DATA}
          tableHeader={tableHeader}
          highlightedColIndex={userLoyalty.highlightedColIndex}
          isScrolled={isScrolled}
        />
        {/* <div className="global--small text-[#6F7375] mx-[22px] max-w-[763px]">
          * You can subscribe for the Personal loan or Credit builder loan and get access to the Credit monitoring dashboard.
          This membership costs $XX.XX/month for one product (Credit Builder/Personal loan) or $YY.YY/month for two. Verify eligibility conditions.
        </div> */}
      </div>
    </DashboardLayout>
  );
};
