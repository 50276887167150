import * as React from "react";
import { DashboardContainer } from "@containers/dashboard";
import {
  Button,
  CardBox,
  SidePanel,
} from "@brainfinance/icash-component-library";
import { UserContext } from "@helpers/user";
import { InstantLoanBanner } from "@containers/dashboard/dashboard-banners/instant-loan-banner";
import { ProvinceLicense } from "@containers/province-license";
import { GatsbyLink } from "@components/gatsby-link";
import { Subheading } from "@components/typography";
import { getUserSessionId } from "@helpers/auth";
import { FaqHandler } from "@containers/faq-handler";
import { slugify } from "@helpers/slugify";

//images
import iconCashback from "@images/symbol-cashback.svg";
import iconReferral from "@images/symbol-referral.svg";
import { mariaFetch } from "@helpers/maria-fetch";

export const IndexDashboardPage = () => {
  const { userData } = React.useContext(UserContext);
  const [dashboardInfo, setDashboardInfo] = React.useState<any>({});
  const [provinceOpen, setProvinceOpen] = React.useState<boolean>(false);
  const [referralOpen, setReferralOpen] = React.useState<boolean>(false);
  const [cashbackOpen, setCashbackOpen] = React.useState<boolean>(false);
  const [personalOpen, setPersonalOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    mariaFetch("/user/dashboard", {
      method: "GET",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserSessionId()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDashboardInfo(data.result);
      });
  }, []);

  React.useEffect(() => {
    if (userData.provinceId === 6) {
      // Checking if Manitoba
      setProvinceOpen(true);
    }
  }, [userData]);

  return (
    <DashboardContainer>
      <div className="w-full max-w-[1138px] p-[44px] md:p-[22px] sm:p-[11px]">
        <div className="sm:px-[11px]">
          <h1 className="global--subheading mb-[11px] sm:text-[37px] sm:leading-[50px]">
            Products
          </h1>
          <p className="global--paragraph text-interface-300 mb-[33px] sm:mb-[22px]">
            Select product you would like to use.
          </p>
        </div>
        <InstantLoanBanner
          status={dashboardInfo?.status}
          data={dashboardInfo?.data}
        />
        <div className="sm:px-[11px]">
          <h2 className="global--subheading mt-[88px] mb-[11px] sm:mt-[44px] sm:text-[30px] sm:leading-[31px]">
            Programs
          </h2>
          <p className="global--paragraph text-interface-300 mb-[33px] sm:mb-[22px]">
            Our programs available for you.
          </p>
        </div>
        <div className="flex w-full gap-[22px] overflow-x-auto md:flex-wrap sm:gap-[11px] sm:mb-[22px]">
          <CardBox className="w-1/2 min-w-[430px] overflow-hidden max-h-[300px] md:min-w-0 md:w-full sm:max-h-[160px]">
            <img
              src={iconCashback}
              alt="Instant loan"
              className="h-[266px] sm:h-[145px] absolute left-[324px] md:left-[72%] top-[17px] sm:left-[initial] sm:right-0"
            />
            <div className="relative w-full max-w-[255px] sm:max-w-[190px] mt-[10px]">
              <h2 className="global--title mb-[33px] sm:mb-[6px]">Cashback</h2>
              <p className="global--caption text-interface-300 mb-[44px] sm:hidden">
                iCASH loves is customers for their loyalty! Our Cashback program
                offers 3 different levels of membership.
                <br />
                <span
                  onClick={() => setCashbackOpen(true)}
                  className="global--link leading-[50px]"
                >
                  Learn more
                </span>
              </p>
              <p className="global--caption !text-[13px] leading-[18px] text-interface-300 mb-[15px] hidden sm:block">
                Earn money for your loyalty with our program!
              </p>
              {userData.provinceId !== 6 ? (
                <Button
                  appearance="tertiary"
                  onClick={() => setCashbackOpen(true)}
                  className="!px-[13px] hidden sm:block"
                >
                  View details
                </Button>
              ) : null}
            </div>
          </CardBox>
          <CardBox className="w-1/2 min-w-[430px] overflow-hidden max-h-[300px] md:min-w-0 md:w-full sm:max-h-[160px]">
            <img
              src={iconReferral}
              alt="Instant loan"
              className="h-[266px] sm:h-[145px] absolute left-[324px] md:left-[72%] top-[16px] sm:left-[initial] sm:right-0"
            />
            <div className="relative w-full max-w-[255px] sm:max-w-[190px] mt-[10px]">
              <h2 className="global--title mb-[33px] sm:mb-[6px]">Referral</h2>
              <p className="global--caption text-interface-300 mb-[44px] sm:hidden">
                {userData.referralAmbassadorName !== ""
                  ? userData.referralAmbassadorName +
                    " invited you to try iCash. Finish paying off your first loan and claim your $10 bonus."
                  : "Become an iCASH ambassador and start receiving cashback today!"}
                <br />
                <span
                  onClick={() => setReferralOpen(true)}
                  className="global--link leading-[50px]"
                >
                  Learn more
                </span>
              </p>
              <p className="global--caption !text-[13px] leading-[18px] text-interface-300 mb-[15px] hidden sm:block">
                Become an ambassador and receive money!
              </p>
              {userData.isAmbassador ? (
                <Button
                  appearance="tertiary"
                  onClick={() => setReferralOpen(true)}
                  className="!px-[13px] hidden sm:block"
                >
                  Refer a friend
                </Button>
              ) : null}
            </div>
          </CardBox>
        </div>
      </div>
      <SidePanel
        active={provinceOpen}
        onOpen={() => setProvinceOpen(true)}
        onClose={() => setProvinceOpen(false)}
      >
        <ProvinceLicense province={"Manitoba"} />
      </SidePanel>
      <SidePanel
        active={referralOpen}
        onOpen={() => setReferralOpen(true)}
        onClose={() => setReferralOpen(false)}
      >
        <Subheading>Referral Program</Subheading>
        <p className="global--paragraph mt-[44px]">
          BECOME AN AMBASSADOR
          <br />
          Once you finish repaying your first loan with iCASH, we want to invite
          you to join our iCASH Ambassadors!
          <br />
          SHARE WITH FRIENDS & FAMILY Share your Referral Code with your friends
          and family via email, social media, or SMS.
          <br />
          <br />
          GET PAID
          <br />
          As soon as your invited referral successfully pays off their first
          loan, you receive $20 in cashback. In addition, your referral also
          receives $10 in cashback.
          <br />
          The more invitees you successfully refer, the higher the amount of
          cashback you receive per referral:
          <br />
          1-5 referrals: $20
          <br />
          6-15 referrals: $25
          <br />
          16-25 referrals: $30
          <br />
          26-35 referrals: $35
          <br />
          36-45 referrals: $40
          <br />
          46-55 referrals: $45
          <br />
          56 and more: $50
          <br />
          There is no limit to the amount of cashback you can earn through
          referrals.
          <br />
          <br />
          INVITE PEOPLE YOU KNOW
          <br />
          This program is intended for you to invite people you know such as
          your friends, family and acquaintances. The more you share your
          referral code, the greater the opportunities for you to earn cashback.
          <br />
          <br />
          GOOD TO KNOW
          <br />
          <FaqHandler
            section="referral"
            onFetchFaq={(faqInfo) => (
              <ul>
                {faqInfo.items.map((faq) => (
                  <li>
                    <GatsbyLink to={`/faq/${slugify(faq.question)}`}>
                      {faq.question}
                    </GatsbyLink>
                  </li>
                ))}
              </ul>
            )}
          />
          <br />
          <br />
          SPREAD THE WORD, EARN PERKS!
          <br />
          With our new and amazing referral program, iCASH wants to thank our
          loyal customer by offering cashback for referrals! What could be
          easier? By simply spreading the news of your excellent experience with
          iCASH, we show our gratitude and return the favour with cashback for
          all!
        </p>
      </SidePanel>
      <SidePanel
        active={cashbackOpen}
        onOpen={() => setCashbackOpen(true)}
        onClose={() => setCashbackOpen(false)}
      >
        <Subheading>Cashback program</Subheading>
        <p className="global--paragraph mt-[44px]">
          Have you ever wondered how you could get paid back for borrowing
          money? Well, with the iCash Cashback program, you can! This program,
          exclusive to iCash customers, is designed to put money back in your
          pocket when you need to borrow.
          <br />
          <br />
          Even though you may need to borrow money for an emergency purchase or
          unexpected bill, you can still come out ahead with the Cashback
          program. It's our goal to help you reach your financial goals faster,
          so what better way to do so than with some real cash in hand?
          <br />
          <br />
          <b>Here's How it Works</b>
          <br />
          <br />
          There are three levels to the Cashback program, Elite, Prestige and
          VIP! As an iCash customer, you are automatically enrolled in the
          program at the Elite level once you've paid off your first loan in its
          entirety. This entitles you to 5% in Cashback on the cost of
          borrowing. Pretty cool, right?! <br />
          <br />
          But, that's not all. If you're looking to maximize your Cashback
          earnings, you can be rewarded even more. The more you borrow, the more
          you earn. In time, you can upgrade to the Prestige or VIP level -
          depending on your loan status. <br />
          <br />
          Prestige members earn an additional 5% - for a total of 10% in
          Cashback. And for those who want to earn the most, VIP members enjoy
          an additional 10% - for a grand total of 20% in Cashback! The best
          part is that there is no limit to how much you can earn. So, the next
          time you need to borrow, remember the iCash Cashback program and put
          some money back in your pocket!
          <br />
          <br />
          <b>Your Earnings</b>
          <br />
          <br />
          Once you accumulate a minimum of $20 in Cashback rewards, you can
          redeem your funds into your bank account. Plus, you can use your
          rewards for anything - whether it's to help cover the cost of your
          next loan, or simply to have some extra spending money. Whatever the
          case, it's your hard-earned cash, so use it how you see fit!
          <br />
          <br />
          To learn more about our Cashback program or to redeem your rewards,
          please visit our website at iCASH.ca or speak to a customer service
          representative. We hope you take advantage of this program and enjoy
          putting some extra money back in your pocket!
          <br />
          <br />
        </p>
      </SidePanel>
      <SidePanel
        active={personalOpen}
        onOpen={() => setPersonalOpen(true)}
        onClose={() => setPersonalOpen(false)}
      >
        Personal loan
      </SidePanel>
    </DashboardContainer>
  );
};
