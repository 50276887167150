import * as React from "react";
import { Button, QrcodeGenerator, InviteSocialMedia, Tab, Tabs, InviteEmail, Platform } from "@brainfinance/icash-component-library";
import { Helmet } from "react-helmet";
//Images
import linkIcon from "@images/link-white.svg";
import portraitImg from "../../../images/invite-social-media/portrait.jpg";
import squareImg from "../../../images/invite-social-media/square.jpg";
import landscapeImg from "../../../images/invite-social-media/landscape.jpg";

export const ReferralInviteFriends = (props: {data: Record<string, any>}) => {
  const inputRef = React.useRef(null);
  const [shareMessage, setShareMessage] = React.useState<string>(props.data.socialMessages.default);
  const [shareImage, setShareImage] = React.useState<string>();
  const [shareImageWidth, setShareImageWidth] = React.useState<string>();
  const [shareImageHeight, setShareImageHeight] = React.useState<string>();

  const sendEmail = (emails: string[], message: string) => {
    window.location.href = "mailto:?&bcc="+emails.join(",")+"&subject="+props.data.emailMessages.subject+"&body="+encodeURIComponent(message);
  }

  const downloadBase64File = (base64Data: string, fileName: string) => {
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const imageInfo = (image: string) => {
    setShareImage(image);
    let img = document.createElement("img")
    img.setAttribute("src", image)
    setTimeout(function(){
      setShareImageWidth(img.width.toString());
      setShareImageHeight(img.height.toString());
    }, 0)
  }

  const shareSocialMedia = (platform: string, imgSrc: string | undefined) => {
    if (!!imgSrc) {
      imageInfo(imgSrc);
      if (platform === "download") {
        downloadBase64File(imgSrc, "iCash-referral-banner.png");
      } else if (platform === "whatsapp") {
        setShareMessage(props.data.socialMessages.whatsApp);
        let shareLinkWa = "https://web.whatsapp.com/send?text="+props.data.socialMessages.whatsApp;
        return window.open(shareLinkWa,'name','width=700,height=600');
      } else if (platform === "twitter") {
        setShareMessage(props.data.socialMessages.twitter);
        return window.open('https://twitter.com/intent/tweet?text='+encodeURIComponent(props.data.socialMessages.twitter),'iCASH','width=600,height=400');
      } else if (platform === "linkedin") {
        setShareMessage(props.data.socialMessages.linkedIn);
        return window.open("https://www.linkedin.com/sharing/share-offsite/?url="+props.data.link,"name","width=600,height=400");
      }
    }
  };

  const handleClick = (ref: any) => {
    navigator.clipboard.writeText(ref.current?.value);
  }
  const handleFocus = (event: any) => event.target.select();

  const tabs = [
    {
      id: "social-media",
      name: "Social media",
      content: (
        <div className="mt-[-26px] pb-[44px]">
          <InviteSocialMedia
            imagesInfo={{
              portrait: {
                src: portraitImg,
                qrCodeTop: 385,
                qrCodeLeft: 180,
              },
              square: {
                src: squareImg,
                qrCodeTop: 360,
                qrCodeLeft: 370,
              },
              landscape: {
                src: landscapeImg,
                qrCodeTop: 195,
                qrCodeLeft: 355,
              }
            }}
            onSubmit={(platform: Platform, imgSrc: string | undefined) => shareSocialMedia(platform, imgSrc) }
            referralLink={props.data.link}
          />
        </div>
      )
    },
    {
      id: "referral-link",
      name: "Link",
      content: (
        <div className="px-[44px] pb-[44px]">
          <p className="global--caption mb-[33px]">Share your unique Referral link to your friends and family over message or SMS. The more you share your Referral link, the greater the opportunities for you to earn cashback.</p>
          <p className="global--label text-interface-300 mb-[12px]">Referral link</p>
          <input type="text" className="text-[20px] text-black font-500 overflow-hidden text-ellipsis mb-[44px] bg-transparent border-0 w-full max-w-[450px] block" onClick={handleFocus} ref={inputRef} value={props.data.link} />
          <Button
            appearance="primary"
            notification="Link copied!"
            onClick={() => handleClick(inputRef)}
          >
            <img src={linkIcon} width="20" height="20" className="inline-block mr-[10px]" />Copy link
          </Button>
        </div>
      )
    },
    {
      id: "qr-code",
      name: "QR Code",
      content: (
        <>
          <p className="global--caption mb-[33px] px-[44px]">Show your unique QR Code to your friends and family in real life. Once they scan the code with their phone's camera, they will be redirected to iCash via your referral link.</p>
          <div className="flex w-full justify-center py-[44px] bg-interface-100">
            <div className="rounded-[16px] overflow-hidden">
              <QrcodeGenerator value={props.data.link} size={365} bgColor="#ffffff" includeMargin />
            </div>
          </div>
        </>
      )
    },
    {
      id: "referral-email",
      name: "Email",
      content: (
        <div className="px-[44px] pb-[44px]">
          <InviteEmail
            emails={[]}
            message={props.data.emailMessages.body}
            onSubmit={(emails: string[], message: string) => sendEmail(emails, message)}
          />
        </div>
      )
    },
  ];

  const [selectedTabId, setSelectedTabId] = React.useState(tabs[0].id);
  const selectedTabContent = React.useMemo(
    () => tabs.find((tab) => tab.id === selectedTabId)?.content,
    [selectedTabId, props.data]
  );

  return (
    <div className="w-full max-w-[640px]">
      <Helmet>
        <meta property="og:title" content={props.data.emailMessages.subject}/>  
        <meta property="og:description" content={shareMessage}/>
        <meta property="og:image" content={shareImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content={shareImageWidth} />
        <meta property="og:image:height" content={shareImageHeight} />
      </Helmet>
      <h2 className="global--small-title pl-[44px] mb-[24px] pt-[28px]">Invite friends</h2>
      <Tabs size="small">
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            onClick={() => setSelectedTabId(tab.id)}
            isSelected={selectedTabId === tab.id}
            className="first:ml-[38px]"
          >
            {tab.name}
          </Tab>
        ))}
      </Tabs>
      <div className="pt-[26px] max-h-full" style={{overflow: "overlay"}}>{selectedTabContent}</div>
    </div>
  );
};
