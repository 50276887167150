import * as React from "react";
import {
  Button,
  CardBox,
  Price,
  TableListCell,
  TableListRow,
  Tabs,
  Tab,
  SummaryBox,
  SummaryDivider,
  LabeledValue,
  FaqPreview,
  FaqPreviewAnswer,
  SidePanel,
  ConfettiModal,
  DashboardSkeleton,
} from "@brainfinance/icash-component-library";
import { DashboardContainer } from "@containers/dashboard";
import { DefaultHeader } from "../instant-loan-page/content-header.style";
import { calcDate } from "@helpers/getDateDifference";
import { GatsbyLink } from "@components/gatsby-link";
import { Subheading } from "@components/typography";
import { UserContext } from "@helpers/user";
import { navigate } from "gatsby";
import { SupportPanel } from "@containers/support-panel";

// Images
import interacIcon from "@images/interacLogo.svg";
import cashbackIcon from "@images/symbol-cashback.svg";
import referralIcon from "@images/symbol-referral.svg";
import walletlImg from "@images/symbol-wallet.svg";
import { getUserSessionId } from "@helpers/auth";
import { FaqHandler } from "@containers/faq-handler";
import { mariaFetch } from "@helpers/maria-fetch";

const summaryArgs = {
  className: "relative",
  style: {
    backgroundImage: `url(${walletlImg})`,
    backgroundPosition: "209px 13px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "180px auto",
  },
};

export const WalletPage = () => {
  const { userData } = React.useContext(UserContext);
  const [walletData, setWalletData] = React.useState<any | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [totalAvailable, setTotalAvailable] = React.useState<number>(0);
  const [redeemMessage, setRedeemMessage] = React.useState<string>("");

  React.useEffect(() => {
    mariaFetch("/user/wallet/", {
      method: "GET",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserSessionId()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const ambassadorAvailable =
          data.result?.balances?.ambassadorBonus?.available ?? 0;
        const referralProgramAvailable =
          data.result?.balances?.referralProgram?.available ?? 0;
        const referralBonusAvailable =
          data.result?.balances?.referralBonus?.available ?? 0;
        const instantLoanAvailable =
          data.result?.balances?.instantLoan?.available ?? 0;
        setTotalAvailable(
          referralProgramAvailable +
            instantLoanAvailable +
            ambassadorAvailable +
            referralBonusAvailable
        );
        setRedeemMessage(data.result?.redeemInfo?.redeemInfoMsg ?? "");
        setWalletData(data.result);
      });
  }, []);

  const tabs = [
    {
      id: "wallet-paid",
      name: "Paid",
      content: walletData?.redeemHistory?.paid.length ? (
        walletData?.redeemHistory.paid.map((item: any, ndx: number) => (
          <CardBox
            key={ndx}
            className="bg-transparent border border-interface-150 py-[20px]"
          >
            <div className="flex">
              <p className="global--caption font-500 text-interface-300 w-3/4">
                {new Date(item.date).toLocaleDateString("en-US", {
                  weekday: "long",
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  formatMatcher: "best fit",
                })}
              </p>
              <p className="global--caption font-500 text-interface-300 w-1/4">
                ${item.amount}
              </p>
            </div>
          </CardBox>
        ))
      ) : (
        <p className="global--caption text-interface-300">
          You did not redeemed money from your Wallet as of today.
        </p>
      ),
    },
    {
      id: "wallet-pending",
      name: "Pending Payment",
      content: walletData?.redeemHistory?.pendingPayment.length ? (
        walletData?.redeemHistory.pendingPayment.map(
          (item: any, ndx: number) => (
            <CardBox
              key={ndx}
              className="bg-transparent border border-interface-150 py-[20px]"
            >
              <div className="flex">
                <p className="global--caption font-500 text-interface-300 w-3/4">
                  {new Date(item.date).toLocaleDateString("en-US", {
                    weekday: "long",
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                    formatMatcher: "best fit",
                  })}
                </p>
                <p className="global--caption font-500 text-interface-300 w-1/4">
                  ${item.amount}
                </p>
              </div>
            </CardBox>
          )
        )
      ) : (
        <p className="global--caption text-interface-300">
          You don't have any pending cashback payments.
        </p>
      ),
    },
  ];

  const [selectedTabId, setSelectedTabId] = React.useState(tabs[0].id);
  const selectedTabContent = React.useMemo(
    () => tabs.find((tab) => tab.id === selectedTabId)?.content,
    [selectedTabId, walletData]
  );

  const onRedeemCashback = async () => {
    const response = await mariaFetch("/cashback/redeem", {
      method: "POST",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        Authorization: `Bearer ${getUserSessionId()}`,
      },
    });
    const data = (await response.json()) as {
      result?: { redeemed: boolean; message: string };
      error?: { code: number; message: string };
    };

    if (data.error) {
      alert(data.error.message);
    }

    if (data.result) {
      if (data.result.redeemed === true) {
        setShowModal(true);
        setTotalAvailable(0);
      } else {
        alert(data.result.message);
      }
    }
  };

  if (walletData == null) {
    return (
      <DashboardContainer>
        <DefaultHeader className="h-fit">
          <Subheading className="p-[44px] pb-[37px]">Wallet</Subheading>
        </DefaultHeader>
        <div className="m-[44px] flex gap-[22px] max-w-[1050px] md:flex-wrap md:m-[22px] sm:m-[11px]">
          <DashboardSkeleton />
        </div>
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer>
      <DefaultHeader className="h-fit">
        <Subheading className="p-[44px] pb-[37px]">Wallet</Subheading>
      </DefaultHeader>
      <div className="m-[44px] flex gap-[22px] max-w-[1050px] md:flex-wrap md:m-[22px] sm:m-[11px]">
        <div className="flex gap-[22px] w-full max-w-[1050px]">
          <div className="flex flex-col w-full max-w-[640px] flex-grow-[1]">
            <CardBox>
              <h2 className="global--title mb-[32px] pt-[3px]">
                Current balance 💰
              </h2>
              <p className="global--label text-interface-300 mb-[12px]">
                Available
              </p>
              <Price
                amount={totalAvailable}
                size="large"
                className="mb-[34px]"
              />
              {walletData.redeemInfo?.isRedeemable === false ? (
                <p className="text-interface-300 global--caption mt-[24px]">
                  {totalAvailable === 0
                    ? "The minimum payout is $20 of available money in your Wallet."
                    : redeemMessage}
                  <span
                    onClick={() => setIsOpen(true)}
                    className="global--link block mt-[11px]"
                  >
                    Learn more
                  </span>
                </p>
              ) : (
                <Button appearance="primary" onClick={onRedeemCashback}>
                  <img
                    src={interacIcon}
                    alt="Interac"
                    className="w-[33px] mr-[10px] my-[-5px] inline-block"
                  />
                  Redeem now
                </Button>
              )}
            </CardBox>
            <CardBox className="sm:overflow-x-auto">
              <h2 className="global--title pt-[3px]">Balance details</h2>
              <table className="w-full">
                <thead>
                  <tr className="table-list--header">
                    <TableListCell className="global--small text-inter"></TableListCell>
                    <TableListCell className="global--small text-inter">
                      Pending
                    </TableListCell>
                    <TableListCell className="global--small text-inter">
                      Available
                    </TableListCell>
                    <TableListCell className="global--small text-inter"></TableListCell>
                  </tr>
                </thead>
                <TableListRow>
                  <TableListCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                    <img
                      src={cashbackIcon}
                      alt="test"
                      className="w-[50px] h-[50px] pl-[14px] bg-white border border-[#D2D2D2] rounded-full inline-block mr-[11px]"
                    />{" "}
                    <GatsbyLink
                      to={"/dashboard/instant-loan"}
                      title="Instant Loan Cashback"
                    >
                      Instant Loan Cashback
                    </GatsbyLink>
                  </TableListCell>
                  <TableListCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                    <img
                      src={cashbackIcon}
                      alt="test"
                      className="w-[50px] h-[50px] pl-[14px] bg-white border border-[#D2D2D2] rounded-full inline-block mr-[11px]"
                    />{" "}
                    <GatsbyLink
                      to={"/dashboard/instant-loan"}
                      title="Instant Loan Cashback"
                    >
                      Instant Loan Cashback
                    </GatsbyLink>
                  </TableListCell>
                  <TableListCell>
                    <Price
                      size="xsmall"
                      amount={walletData?.balances?.instantLoan.pending || 0}
                      className="text-[#6F7375]"
                    />
                  </TableListCell>
                  <TableListCell>
                    <Price
                      size="xsmall"
                      amount={walletData?.balances?.instantLoan.available || 0}
                    />
                  </TableListCell>
                </TableListRow>
                <TableListRow>
                  <TableListCell>
                    <img
                      src={referralIcon}
                      alt="test"
                      className="w-[50px] h-[50px] pl-[14px] bg-white border border-[#D2D2D2] rounded-full inline-block mr-[11px]"
                    />{" "}
                    <GatsbyLink to={"/dashboard/referral-program"}>
                      Referral Program
                    </GatsbyLink>
                  </TableListCell>
                  <TableListCell>
                    <Price
                      size="xsmall"
                      amount={
                        walletData?.balances?.referralProgram?.pending || 0
                      }
                      className="text-[#6F7375]"
                    />
                  </TableListCell>
                  <TableListCell>
                    <Price
                      size="xsmall"
                      amount={
                        walletData?.balances?.referralProgram?.available || 0
                      }
                    />
                  </TableListCell>
                </TableListRow>
                <tfoot>
                  <tr className="table-list--footer">
                    <TableListCell className="global--small text-inter"></TableListCell>
                    <TableListCell className="global--small text-inter">
                      <Price
                        size="xsmall"
                        amount={walletData?.summary?.pending || 0}
                        className="text-[#6F7375]"
                      />
                    </TableListCell>
                    <TableListCell className="global--small text-inter">
                      <Price size="xsmall" amount={totalAvailable || 0} />
                    </TableListCell>
                    <TableListCell className="global--small text-inter">
                      Total
                    </TableListCell>
                  </tr>
                </tfoot>
              </table>
            </CardBox>
            <CardBox className="max-h-[480px] overflow-hidden">
              <h2 className="global--title mb-[24px] pt-[3px]">
                Previous redeems
              </h2>
              <Tabs size="small">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.id}
                    onClick={() => setSelectedTabId(tab.id)}
                    isSelected={selectedTabId === tab.id}
                    className="first:ml-[24px]"
                  >
                    {tab.name}
                  </Tab>
                ))}
              </Tabs>
              <div className="mt-[26px] min-h-[22px] max-h-[324px] overflow-auto">
                {selectedTabContent}
              </div>
            </CardBox>
          </div>
        </div>
        <WalletSummaryBox data={walletData?.summary} />
      </div>
      <SidePanel active={isOpen} onOpen={handleOpen} onClose={handleClose}>
        <Subheading>HOW I REDEEM MY CASHBACK?</Subheading>
        <p className="global--paragraph leading-7 mt-[44px]">
          1 - Make sure you have at least $20 of available Cashback.
          <br />
          2 - Make sure you can accept an e-transfer with the email you have
          used to log in to your iCASH member account.
          <br />
          3 - Click on the Redeem button and follow the instructions.
          <br />
          4 - Wait 5 minutes to receive your e-transfer! Yes, it’s that easy!
          <br />
          <br />
          Conditions to Redeem:
          <br />
          - Have your account in good standing*
          <br />
          - Have a minimum of $20 of available cashback
          <br />
          - Be able to receive your Cashback by e-transfer (to update your email
          address please contact us)
          <br />
          <br />* For more information, visit our Cashback Program section of
          our Terms and Conditons.
        </p>
      </SidePanel>
      {showModal && (
        <ConfettiModal
          onClose={() => setShowModal(false)}
          className="w-full max-w-[673px]"
        >
          <div className="p-[44px]">
            <h2 className="global--small-title mb-[28px] pt-[11px]">
              Earned money on it’s way 🎉
            </h2>
            <p className="text-interface-300 global--caption mb-[33px]">
              Hooray! You successfully redeemed your iCash Wallet!
            </p>
            <p className="global--label text-interface-300 mb-[12px]">
              Redeemed amount
            </p>
            <Price
              amount={totalAvailable || 0}
              size="large"
              className="mb-[34px]"
            />
            <p className="text-interface-300 global--caption">
              Wait 5 minutes to receive your e-transfer!
              <br />
              It pays to borrow with iCash and gives you savings!
            </p>
            {!userData.hasActiveLoan ? (
              <div className="flex w-full justify-end mt-[44px]">
                <Button
                  appearance="primary"
                  onClick={() => {
                    navigate("/dashboard/application-start");
                  }}
                >
                  Start a new instant loan
                </Button>
              </div>
            ) : null}
          </div>
        </ConfettiModal>
      )}
    </DashboardContainer>
  );
};

const WalletSummaryBox = (props: { data: Record<string, any> }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div className="flex flex-col gap-[22px] flex-grow-[1] md:max-w-full min-w-[350px]">
      <SummaryBox {...summaryArgs}>
        <h4 className="summary-box--title">Summary</h4>
        <div className="space-y-[34px]">
          <LabeledValue label="Last redeem">
            {calcDate(new Date(props.data?.lastRedeemDate), new Date())}
          </LabeledValue>
          <LabeledValue label="Pending payment">
            <Price amount={props.data?.pending || 0} size="medium" />
          </LabeledValue>
        </div>
        <SummaryDivider />
        <div className="align-text-bottom">
          <LabeledValue label="Total redeemed" size="large">
            <Price amount={props.data?.totalRedeemed || 0} size="large" />
          </LabeledValue>
        </div>
      </SummaryBox>
      <SummaryBox className="side">
        <FaqHandler
          section="wallet"
          onFetchFaq={(faqInfo) => (
            <FaqPreview>
              <div className="pb-[1.5rem]">
                <h2 className="global--heading">FAQ</h2>
              </div>
              {faqInfo.items.map((item, ndx) => (
                <FaqPreviewAnswer key={ndx} question={item.question}>
                  {item.answer}
                </FaqPreviewAnswer>
              ))}
              <p className="global--caption pt-[2rem]">
                <a href="/faq" className="global--link" target="_blank">
                  Read our full FAQ
                </a>{" "}
                for more information.
                <br />
                <span className="text-interface-300">
                  Have more questions?
                </span>{" "}
                <a
                  onClick={handleOpen}
                  className="global--link"
                  target="_blank"
                >
                  Contact us
                </a>
                .
                <SupportPanel
                  isOpen={isOpen}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                />
              </p>
            </FaqPreview>
          )}
        />
      </SummaryBox>
    </div>
  );
};
