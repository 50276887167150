import * as React from "react";
import { DashboardContainer } from "@containers/dashboard";
import { navigate } from "gatsby";
import { Button, Modal } from "@brainfinance/icash-component-library";

import "./style.css";
import { getUserSessionId } from "@helpers/auth";
import { SupportPanel } from "@containers/support-panel";

type MessageDataType =
  | {
      status: "contact-us";
      data: {
        message: string;
      };
    }
  | {
      status: "ready";
      data: {
        message: string;
      };
    }
  | {
      status: "error";
      data: {
        code: number;
        message: string;
      };
    }
  | {
      status: "success";
      data: {
        contractId: number;
        padId: number;
      };
    };

export const ApplicationContractPage = () => {
  const [iFrameReady, setFrameReady] = React.useState(false);
  const iFrameRef = React.useRef<HTMLIFrameElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  React.useEffect(() => {
    const listenToFrame = (e: MessageEvent<MessageDataType>) => {
      const data = e.data;
      if (data.status === "contact-us") {
        onGoContactUs();
      } else if (data.status === "ready") {
        setFrameReady(true);
      } else if (data.status === "success") {
        onGoToDashboard(data.data.contractId, data.data.padId);
      } else if (data.status === "error") {
        setErrorMessage(data.data.message);
        setShowErrorModal(true);
      }
    };
    window.addEventListener("message", listenToFrame);
    return () => window.removeEventListener("message", listenToFrame);
  }, []);

  React.useEffect(() => {
    if (iFrameReady && iFrameRef.current) {
      iFrameRef.current.contentWindow?.postMessage(
        { token: getUserSessionId() },
        "*"
      );
    }
  }, [iFrameReady, iFrameRef]);

  const onGoContactUs = () => {
    setIsOpen(true);
  };

  const onGoToDashboard = (_: number, __: number) => {
    navigate("/dashboard/instant-loan/active-loan", {
      state: {
        loanBegin: true,
      },
    });
  };

  return (
    <>
      <DashboardContainer bodyClass="application">
        <div className="w-full h-full">
          <iframe
            ref={iFrameRef}
            id="il"
            src={process.env.GATSBY_CONTRACT_SIGNATURE_URL}
            width="100%"
            height="100%"
          ></iframe>
        </div>
        <SupportPanel
          isOpen={isOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </DashboardContainer>
      {showErrorModal && (
        <Modal onClose={() => setShowErrorModal(false)} className="min-h-fit">
          <div className="modal--content pt-[44px]">
            <p className="contract-signature--error-title">An error occured</p>
            <p className="contract-signature--error-message mt-[8px]">
              {errorMessage}
            </p>
            <div className="flex flex-1 justify-end mt-[20px]">
              <Button
                appearance="secondary"
                size="small"
                onClick={() => setShowErrorModal(false)}
                className="mr-[8px]"
              >
                Close
              </Button>
              <Button
                appearance="primary"
                size="small"
                onClick={() => navigate("/dashboard/instant-loan")}
              >
                Dashboard
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
