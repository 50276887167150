import * as React from "react";
import { EndLoanType } from "../types";

import { AlertSection, CardBox } from "@brainfinance/icash-component-library";

import { ContractDownloadLink } from "@components/contract-download/components";
import { dateToYMD } from "../utilites/dateFormat";

export const EndLoanComponent = (props: EndLoanType) => {
  return (
    <CardBox key={props.id} className="flex-[2_0_48%] mb-0">
      <h2 className="global--title mb-[40px] pt-[3px]">Loan #{props.id}</h2>
      <div className="flex flex-col justify-start">
        <div className="flex justify-start">
          <div className="w-1/2">
            <AlertSection>
              <div className="w-full relative">
                <p className="global--label text-interface-300 mb-[10px]">
                  State
                </p>
                <p className="text-[15px] font-700 mb-[22px]">
                  {props.loanStatus === "finished" && "Finished"}
                  {props.loanStatus === "cancelled" && "Cancelled"}
                </p>
              </div>
            </AlertSection>
            <p className="global--label text-interface-300 mb-[10px]">
              Start date
            </p>
            <p className="text-[15px] font-700">{dateToYMD(props.startDate)}</p>
          </div>
          <div className="w-1/2 relative">
            <p className="global--label text-interface-300 mb-[10px]">
              Loan amount
            </p>
            <p className="text-[15px] font-700 mb-[22px]">
              {props.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
            <p className="global--label text-interface-300 mb-[10px]">
              End date
            </p>
            <p className="text-[15px] font-700">{dateToYMD(props.endDate)}</p>
          </div>
        </div>
        {props.agreementId !== undefined &&
        props.padDocumentId !== undefined ? (
          <div className="w-full">
            <div className="w-full border-t-[1px] border-t-[#D2D2D2] mt-[16px] mb-[16px]"></div>
            <p className="global--label text-interface-300 mb-[10px]">
              Documents
            </p>
            <ContractDownloadLink
              title="Loan contract"
              contractId={props.agreementId}
            />
            <ContractDownloadLink
              title="Loan PAD"
              contractId={props.padDocumentId}
            />
          </div>
        ) : null}
      </div>
    </CardBox>
  );
};
