import * as React from "react";
import {
  CardBox,
  LabeledValue
} from "@brainfinance/icash-component-library";
import { GatsbyLink } from "@components/gatsby-link";

type LoanType = { no: number; description: string; amount: number; date: string };

interface CashbackHistoryTabContentProps {
  loans: LoanType[];
}

export const CashbackHistoryTabContent = (props: CashbackHistoryTabContentProps) => (
  <>
    {props.loans.map((loan) => (
      <CardBox key={loan.no} className="bg-transparent border border-interface-150 py-[20px]">
        <div className="flex justify-between items-center">
          <div>
            <GatsbyLink to={"/dashboard/instant-loan"} className="text-[15px]">{loan.description}</GatsbyLink>
            <p className="global--small pt-[6px]">{loan.date}</p>
          </div>
          <LabeledValue label="Cashback" size="mini">
            ${loan.amount}
          </LabeledValue>
          <p className="global--caption text-[#6F7375]">
            Loan repaid <div className="checklist--bullet inline-block align-sub ml-[3px]"></div>
          </p>
        </div>
      </CardBox>
    ))}
  </>
);
