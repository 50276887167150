import * as React from "react";
import { UserContext } from "@helpers/user";
import {
  AlertBanner,
  Button,
  CardBox,
  CashbackProgressBar,
  Checklist,
  ChecklistItem,
  DashboardSkeleton,
  FaqPreview,
  FaqPreviewAnswer,
  LabeledValue,
  Modal,
  Price,
  SidePanel,
  SummaryBox,
  SummaryDivider,
} from "@brainfinance/icash-component-library";
import { ReferralInviteFriends } from "./referral-invite-friends";
import { ReferralListTabs } from "./referral-list-tabs";
import { GatsbyLink } from "@components/gatsby-link";
import { Subheading } from "@components/typography";
import { navigate } from "gatsby";
import { SupportPanel } from "@containers/support-panel";
import { getUserSessionId } from "@helpers/auth";
import { FaqHandler } from "@containers/faq-handler";
import { slugify } from "@helpers/index";

// Images
import imageBack from "@images/symbol-referral.svg";
import { mariaFetch } from "@helpers/maria-fetch";

const summaryArgs = {
  className: "relative w-full",
  style: {
    backgroundImage: `url(${imageBack})`,
    backgroundPosition: "198px 53px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "190px auto",
  },
};

const maxNumberReferrals = (ref: number) => {
  if (ref > 5) return 15;
  else if (ref > 15) return 25;
  else if (ref > 25) return 35;
  else if (ref > 35) return 45;
  else if (ref > 45) return 55;
  else if (ref > 55) return ref;
  else return 5;
};

export const ReferralProgram = () => {
  const { userData } = React.useContext(UserContext);
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [referralData, setReferralData] = React.useState<any>({});
  const [referralListData, setReferralListData] = React.useState<any>({});
  const [maxReferrals, setMaxReferrals] = React.useState<number>(5);
  const [activeReferral, setActiveReferral] = React.useState<number>(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  React.useEffect(() => {
    mariaFetch("/ambassador", {
      method: "GET",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserSessionId()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setReferralData(data.result);
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    mariaFetch("/ambassador/referrals?limit=10&offset=0", {
      method: "GET",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserSessionId()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setReferralListData(data.result);
      });
  }, []);

  React.useEffect(() => {
    let referrals = referralListData?.referrals?.filter(
      (state: any) => state.status === "Success"
    ).length;
    if (referralListData?.referrals?.length && referrals) {
      setMaxReferrals(maxNumberReferrals(referrals));
      setActiveReferral(referrals);
    }
  }, [referralListData]);

  if (loading)
    return (
      <div className="flex flex-col flex-grow-[1] p-[44px]">
        <CardBox>
          <DashboardSkeleton />
        </CardBox>
      </div>
    );
  if (!!error)
    return (
      <div className="flex flex-col flex-grow-[1]">
        <AlertBanner status="error">{error}</AlertBanner>
      </div>
    );

  if (!!referralData)
    return (
      <>
        <div className="m-[44px] flex gap-[22px] max-w-[1050px] flex-wrap md:m-[22px] sm:m-[11px]">
          {referralData?.amountEarned > 0 ? (
            <div className="w-full max-w-[1050px]">
              <AlertBanner status="info">
                <h3 className="global--title mb-[24px]">
                  You've earned Money! 💵
                </h3>
                <p className="text-interface-300 global--caption mb-[12px]">
                  Congratulations, you have earned money!
                </p>
                <p className="text-interface-300 global--caption mb-[24px]">
                  You have <b>${referralData.amountEarned}</b> available to
                  redeem in your{" "}
                  <GatsbyLink to={"/dashboard/wallet"}>iCASH Wallet</GatsbyLink>
                  .
                </p>
                <Button appearance="primary" onClick={() => {}}>
                  Redeem my earnings
                </Button>
              </AlertBanner>
            </div>
          ) : null}
          <div className="flex gap-[22px] w-full max-w-[1050px]">
            <div className="flex flex-col w-full max-w-[640px] flex-grow-[1]">
              {!referralData?.isAmbassador ? (
                <>
                  <CardBox className="overflow-hidden min-h-[266px] sm:min-h-[140px]">
                    <div className="relative">
                      <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
                        Get access to Referral program
                      </h2>
                      <p className="global--caption text-interface-300 mb-[44px] sm:mb-[16px]">
                        Pay off your first loan to become eligible for{" "}
                        <b className="font-600">Referral program</b>.
                      </p>
                      <Button
                        appearance="primary"
                        onClick={() => {
                          navigate("/dashboard/instant-loan");
                        }}
                        className="sm:px-[13px]"
                      >
                        View loan details
                      </Button>
                    </div>
                  </CardBox>
                  <CardBox className="overflow-hidden min-h-[266px] sm:min-h-[140px]">
                    <div className="relative">
                      <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
                        Become an ambassador
                      </h2>
                      <Checklist>
                        <div className="checklist--column mb-[33px]">
                          <ChecklistItem>
                            <p className="global--caption text-[#6F7375]">
                              Completely free
                            </p>
                          </ChecklistItem>
                          <ChecklistItem>
                            <p className="global--caption text-[#6F7375]">
                              Get paid up to $50 per referral
                            </p>
                          </ChecklistItem>
                          <ChecklistItem>
                            <p className="global--caption text-[#6F7375]">
                              No limit to the amount you can earn
                            </p>
                          </ChecklistItem>
                        </div>
                      </Checklist>
                      <p className="global--caption text-interface-300">
                        Become an iCASH ambassador and start receiving cashback
                        today! For each friend you refer, receive <b>$20</b> in
                        bonus once they successfully finish paying their first
                        loan. Your referral will also receive <b>$10</b> in
                        bonus!
                        <br />
                        <br />
                        The more people you invite, the higher the bonus amount
                        you receive per referral, up to a maximum of{" "}
                        <b>$50 per referral</b>!<br />
                        <br />
                        Learn how it works{" "}
                        <span
                          className="global--link"
                          onClick={() => setIsOpen(true)}
                        >
                          here
                        </span>
                        .
                      </p>
                    </div>
                  </CardBox>
                </>
              ) : (
                <>
                  <CardBox className="overflow-hidden min-h-[266px] sm:min-h-[140px]">
                    <div className="relative">
                      <h3 className="global--title mb-[24px]">Refer friends</h3>
                      <p className="global--caption mb-[28px] sm:mb-[16px]">
                        When you refer your friends to iCash, they'll also
                        receive a bonus.
                      </p>
                      <div className="flex mb-[44px]">
                        <LabeledValue
                          label="You receive"
                          size="large"
                          className="w-1/2"
                        >
                          <Price
                            amount={referralData.amountEarnedPerReferral}
                            integer
                            size="large"
                            className="inline-block"
                          />
                          <span className="pl-[5px] text-[20px] tracking-[-0.3px] leading-[22px]">
                            per friend
                          </span>
                        </LabeledValue>
                        <LabeledValue label="Friends receive" className="w-1/2">
                          <Price
                            amount={20}
                            integer
                            size="medium"
                            className="inline-block"
                          />
                        </LabeledValue>
                      </div>
                      <Button
                        appearance="primary"
                        onClick={() => setShowModal(true)}
                        className="sm:px-[13px] mr-[22px]"
                      >
                        Invite friends
                      </Button>
                      <Button
                        appearance="tertiary"
                        onClick={() => {
                          navigate("/dashboard/wallet");
                        }}
                        className="sm:px-[13px]"
                      >
                        Open Wallet
                      </Button>
                    </div>
                  </CardBox>
                  <CardBox className="overflow-hidden min-h-[266px] pr-[22px] sm:min-h-[140px]">
                    <div className="relative">
                      <h3 className="global--title mb-[24px]">Earn more</h3>
                      <p className="global--caption mb-[28px] sm:mb-[16px]">
                        {maxReferrals < 56 ? (
                          "The more friends you refer, the more money you receive per each one."
                        ) : (
                          <>
                            Congratulations! You are a one of our most loyal
                            iCash Ambassadors. You have earned over{" "}
                            <b>${referralData.amountEarned}</b> and invited{" "}
                            <b>{maxReferrals} friends</b> to join iCash.
                          </>
                        )}
                      </p>
                      <p className="global--label text-interface-300 mb-[-26px]">
                        Progress
                      </p>
                      <div className="flex items-end gap-[55px] mb-[44px]">
                        <CashbackProgressBar
                          completedAmount={activeReferral}
                          goalAmount={maxReferrals}
                          unit="referrals"
                          className="w-3/4"
                        />
                        <LabeledValue
                          label={
                            maxReferrals < 56 ? "Next level" : "Maximum level"
                          }
                          className="w-1/4"
                        >
                          <Price
                            amount={
                              referralData.amountEarnedPerReferral + 5 || 20
                            }
                            integer
                            size="medium"
                            className="inline-block"
                          />
                          <span className="pl-[5px] text-[14px] leading-[23px]">
                            per friend
                          </span>
                        </LabeledValue>
                      </div>
                      {maxReferrals < 56 ? (
                        <p className="global--caption text-[#6F7375] mb-[28px] sm:mb-[16px]">
                          The amount you receive per referral can reach up to
                          $50.{" "}
                          <span
                            className="global--link"
                            onClick={() => setIsOpen(true)}
                          >
                            Read more
                          </span>
                          .
                        </p>
                      ) : (
                        <p className="global--caption text-[#6F7375] mb-[28px] sm:mb-[16px]">
                          Keep spreading the word around you to continue earning
                          money. There is no limit to the amount of cashback
                          your earn through referrals.{" "}
                          <span
                            className="global--link"
                            onClick={() => setIsOpen(true)}
                          >
                            Read more
                          </span>
                          .
                        </p>
                      )}
                    </div>
                  </CardBox>
                  {referralListData && !!activeReferral && (
                    <ReferralListTabs
                      data={referralListData}
                      activeRef={activeReferral}
                    />
                  )}
                </>
              )}
            </div>
            <ReferralSummaryBox
              data={userData}
              amountEarned={referralData?.amountEarned}
              activeReferral={activeReferral}
            />
          </div>
        </div>
        <SidePanel active={isOpen} onOpen={handleOpen} onClose={handleClose}>
          <Subheading>How does referral program work?</Subheading>
          <p className="global--paragraph mt-[44px]">
            BECOME AN AMBASSADOR
            <br />
            Once you finish repaying your first loan with iCASH, we want to
            invite you to join our iCASH Ambassadors!
            <br />
            SHARE WITH FRIENDS & FAMILY Share your Referral Code with your
            friends and family via email, social media, or SMS.
            <br />
            <br />
            GET PAID
            <br />
            As soon as your invited referral successfully pays off their first
            loan, you receive $20 in cashback. In addition, your referral also
            receives $10 in cashback.
            <br />
            The more invitees you successfully refer, the higher the amount of
            cashback you receive per referral:
            <br />
            1-5 referrals: $20
            <br />
            6-15 referrals: $25
            <br />
            16-25 referrals: $30
            <br />
            26-35 referrals: $35
            <br />
            36-45 referrals: $40
            <br />
            46-55 referrals: $45
            <br />
            56 and more: $50
            <br />
            There is no limit to the amount of cashback you can earn through
            referrals.
            <br />
            <br />
            INVITE PEOPLE YOU KNOW
            <br />
            This program is intended for you to invite people you know such as
            your friends, family and acquaintances. The more you share your
            referral code, the greater the opportunities for you to earn
            cashback.
            <br />
            <br />
            GOOD TO KNOW
            <br />
            <FaqHandler
              section="referral"
              onFetchFaq={(faqInfo) => (
                <ul>
                  {faqInfo.items.map((faq) => (
                    <li>
                      <GatsbyLink to={`/faq/${slugify(faq.question)}`}>
                        {faq.question}
                      </GatsbyLink>
                    </li>
                  ))}
                </ul>
              )}
            />
            <br />
            <br />
            SPREAD THE WORD, EARN PERKS!
            <br />
            With our new and amazing referral program, iCASH wants to thank our
            loyal customer by offering cashback for referrals! What could be
            easier? By simply spreading the news of your excellent experience
            with iCASH, we show our gratitude and return the favour with
            cashback for all!
          </p>
        </SidePanel>
        {showModal && (
          <Modal
            onClose={() => setShowModal(false)}
            className="w-full max-w-[640px]"
          >
            <ReferralInviteFriends data={referralData} />
          </Modal>
        )}
      </>
    );
  else return null;
};

const ReferralSummaryBox = (props: {
  data: Record<string, any>;
  amountEarned: number;
  activeReferral: number;
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div className="flex flex-col gap-[22px] flex-grow-[1] md:max-w-full min-w-[350px]">
      <SummaryBox {...summaryArgs}>
        <h4 className="summary-box--title">Program</h4>
        <div className="space-y-[34px]">
          <LabeledValue
            label={
              !props.data.isAmbassador ? "Friend limit" : "Friends applied"
            }
          >
            <span className="global--heading">
              {!props.data.isAmbassador ? "∞" : props.activeReferral}
            </span>
          </LabeledValue>
          <LabeledValue
            label={
              !props.data.isAmbassador ? "Friends receive" : "Pending cashback"
            }
          >
            <Price amount={!props.data.isAmbassador ? 20 : 45} size="medium" />
          </LabeledValue>
        </div>
        <SummaryDivider />
        <div className="align-text-bottom">
          <LabeledValue
            label={
              !props.data.isAmbassador ? "You receive up to" : "Total earned"
            }
            size="large"
          >
            <Price
              amount={!props.data.isAmbassador ? 50 : props.amountEarned || 0}
              size="large"
              className="inline-block"
            />
            {!props.data.isAmbassador ? (
              <span className="pl-[5px] text-[20px] tracking-[-0.3px] leading-[22px]">
                per friend
              </span>
            ) : (
              ""
            )}
          </LabeledValue>
        </div>
      </SummaryBox>
      <SummaryBox className="side w-full">
        <FaqHandler
          section="referral"
          onFetchFaq={(faqInfo) => (
            <FaqPreview>
              <div className="pb-[1.5rem]">
                <h2 className="global--heading">FAQ</h2>
              </div>
              {faqInfo.items.map((item, ndx) => (
                <FaqPreviewAnswer key={ndx} question={item.question}>
                  {item.answer}
                </FaqPreviewAnswer>
              ))}
              <p className="global--caption pt-[2rem]">
                <a href="/faq" className="global--link" target="_blank">
                  Read our full FAQ
                </a>{" "}
                for more information.
                <br />
                <span className="text-interface-300">
                  Have more questions?
                </span>{" "}
                <a
                  onClick={handleOpen}
                  className="global--link"
                  target="_blank"
                >
                  Contact us
                </a>
                .
                <SupportPanel
                  isOpen={isOpen}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                />
              </p>
            </FaqPreview>
          )}
        />
      </SummaryBox>
    </div>
  );
};
