import * as React from "react";

import { DashboardContainer } from "@containers/dashboard";
import { Button } from "@brainfinance/icash-component-library";
import { navigate } from "gatsby";

import InformationIcon from "@images/info.svg";
import { getUserSessionId } from "@helpers/auth";
import { mariaFetch } from "@helpers/maria-fetch";

export const EmailVerificationSent = () => {
  const [email] = React.useState<string>(() => {
    const query = new URLSearchParams(location.search);
    const data = query.get("newEmail");
    return data ?? "";
  });

  const onReturn = () => {
    navigate("/dashboard/profile/change-email");
  };

  const onResendEmail = async () => {
    try {
      await mariaFetch("/user/changeemail", {
        method: "POST",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          Authorization: `Bearer ${getUserSessionId()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newEmail: email }),
      });
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <DashboardContainer>
      <div className="w-full max-w-[1138px] p-[44px] md:p-[22px] sm:p-[11px]">
        <div className="sm:px-[11px]">
          <h1 className="global--subheading mb-[11px] text-[#707070] sm:text-[37px] sm:leading-[50px]">
            Email verification
          </h1>
        </div>
        <div className="flex-[2_0_40%] last:max-w-[40%] mb-0">
          <p className="global--label text-[#707070] font-400 mb-[10px] whitespace-pre-line">
            We have sent a confirmation link to your new email address:{" "}
            <span className="font-700 ml-[8px]">{email}</span>
            {"\n"}In the received email click the link to verify your new
            address.
          </p>
          {/* IMG */}
          <p className="global--label text-[#707070] font-400 mb-[10px] whitespace-pre-line">
            Didn't receive an email from us? Please check your spam filter or
            junk mail folder first.{"\n"}Not there?{" "}
            <span
              onClick={onResendEmail}
              className="underline underline-offset-1 cursor-pointer"
            >
              Re-send email
            </span>
          </p>
          <div className="flex flex-1 flex-row">
            <img src={InformationIcon} alt="information" />
            <p className="global--label text-[#707070] ml-[10px] font-400 mb-[10px] mt-[20px]">
              Please note that your upcoming e-transfers will be sent to this
              new email address.
            </p>
          </div>
          <div className="flex flex-1 justify-end">
            <Button appearance="secondary" onClick={onReturn}>
              Return
            </Button>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};
