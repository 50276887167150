export const formatPhoneNumberWithCountryCode = (str: string | undefined) => {
  let phoneNumber = str;
  if (!phoneNumber || phoneNumber.length === 0) {
    return null;
  }
  const extension = phoneNumber[0];
  phoneNumber.slice(0, 1);
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");

  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    let intlCode = match[1] ? `+${extension} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
};
