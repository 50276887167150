import * as React from "react";
import { UserContext } from "@helpers/user";

import { DashboardContainer } from "@containers/dashboard";
import { Button, TextInput } from "@brainfinance/icash-component-library";
import { navigate } from "gatsby";
import { getUserSessionId } from "@helpers/auth";
import { mariaFetch } from "@helpers/maria-fetch";

const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const ChangeEmail = () => {
  const { userData } = React.useContext(UserContext);
  const [enable, setEnable] = React.useState(false);
  const [newEmail, setNewEmail] = React.useState<string>("");
  const [error, setError] = React.useState<string>();

  const onCancel = () => {
    navigate("/dashboard/profile");
  };

  const onSendVerificationEmail = async () => {
    try {
      const res = await mariaFetch("/user/changeemail", {
        method: "POST",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          Authorization: `Bearer ${getUserSessionId()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newEmail: newEmail }),
      });
      try {
        const data = await res.json();
        if (data && data.error) {
          setError(data.error?.message ?? "");
        }
      } catch (error) {}
      if (res.status === 204) {
        navigate(
          "/dashboard/profile/email-verification-sent?newEmail=" + newEmail
        );
      }
    } catch (error) {
      console.warn(error);
    }
  };

  React.useEffect(() => {
    setError(undefined);
    if (validateEmail(newEmail)) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [newEmail]);

  return (
    <DashboardContainer>
      <div className="w-full max-w-[1138px] p-[44px] md:p-[22px] sm:p-[11px]">
        <div className="sm:px-[11px]">
          <h1 className="global--subheading mb-[11px] text-[#707070] sm:text-[37px] sm:leading-[50px]">
            Change email address
          </h1>
        </div>
        <div className="flex-[2_0_48%] last:max-w-[55%] mb-0">
          <p className="global--label text-[#707070] font-400 mb-[10px]">
            This process will allow you to update your iCASH email address.
          </p>
          <p className="global--label text-[#707070] font-400 mb-[10px]">
            To begin the process, enter your new email below and click on
          </p>
          <p className="global--label text-[#707070] font-400 mb-[10px] italic">
            Send verification email.
          </p>
          <p className="global--label text-[#707070] font-400 mb-[10px]">
            Current email address:
            <span className="font-700 ml-[8px]">{userData.email}</span>
          </p>
          <p className="global--label font-400 text-[#707070] mb-[10px]">
            e-Transfer will be sent to this email address
          </p>
          <TextInput
            label="New email address"
            onChange={(value) => {
              if (typeof value === "string") {
                setNewEmail(value);
              }
            }}
            value={newEmail}
          />
          {error && (
            <p className="global--label font-400 text-[#FF0033] mb-[10px]">
              {error}
            </p>
          )}
          <div className="flex flex-1 flex-row justify-between mt-[16px]">
            <Button appearance="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              appearance="primary"
              disabled={!enable}
              onClick={onSendVerificationEmail}
            >
              Send verification email
            </Button>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};
