import * as React from "react";
import { navigate } from "gatsby";
import { isLoggedIn } from "@helpers/auth";

type AuthRouteProps = { path: string; component: React.FC<any> };

export const AuthRoute = (props: AuthRouteProps) => {
  if (!isLoggedIn()) {
    navigate("/login");
    return null;
  }

  return <props.component {...props} />;
};
