import * as React from "react";
import { ContractDownloadLinkPropsType } from "../types";

// Images
import dwldIcon from "@images/ic-download.svg";
import { getUserSessionId } from "@helpers/auth";
import { mariaFetch } from "@helpers/maria-fetch";

export const ContractDownloadLink = (
  props: React.PropsWithChildren<ContractDownloadLinkPropsType>
) => {
  const downloadWithContentBase64 = async (data: string, fileName: string) => {
    const r = await fetch("data:application/pdf;base64," + data);
    const blob = await r.blob();

    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onClickDownload = async () => {
    try {
      const res = await mariaFetch(`/document/${props.contractId}`, {
        method: "GET",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
      });
      const data = await res.json();
      if (data.error) {
        console.warn("failed to download: " + data.error?.message);
      } else if (res.status === 200) {
        const binary: string = data.result?.binary ?? "";
        const filename: string = data.result?.filename ?? "contract";
        await downloadWithContentBase64(binary, filename);
      }
    } catch (error: any) {
      console.warn("failed to download: " + error.message);
    }
  };

  return (
    <div
      onClick={onClickDownload}
      className="block text-[14px] text-primary hover:text-primary-emphasis font-500 cursor-pointer mt-[8px] w-fit"
    >
      {props.title} <img src={dwldIcon} className="inline-block mt-[-3px]" />
    </div>
  );
};
