import * as React from "react";
import { navigate } from "gatsby";
import {
  AlertBanner,
  AlertSection,
  Button,
  CardBox,
  DashboardSkeleton,
  Price,
  SidePanel,
} from "@brainfinance/icash-component-library";
import {
  DashboardBannerProps,
  LoanBasicsProps,
} from "./dashboard-banners-props";
import { GatsbyLink } from "@components/gatsby-link";
import { Subheading } from "@components/typography";
import { UserContext } from "@helpers/user";
//images
import iconInstant from "@images/products/ic-instant-loans-xl.svg";
import goodImg from "../../../images/proof/good.jpg";
import wrongImg from "../../../images/proof/wrong.png";
import { getUserSessionId } from "@helpers/auth";
import {
  ActiveInstantLoan,
  CollectionInstantLoan,
  InstantLoanDashboard,
  InstantLoanDashboardActiveLoan,
  PendingDisburseInstantLoan,
  WaitingForPayoffInstantLoan,
} from "../instant-loan/types";
import { getBigDateFormat } from "@helpers/dateFormat";
import { dateFormatDay } from "@components/loan-history-cards/utilites/dateFormat";
import { mariaFetch } from "@helpers/maria-fetch";

export const InstantLoanBanner = (props: DashboardBannerProps) => {
  const { userData } = React.useContext(UserContext);
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(true);

  const [dashboardInfo, setInstantActiveLoan] =
    React.useState<InstantLoanDashboard>();

  React.useEffect(() => {
    const onFetchData = async () => {
      try {
        const response = await mariaFetch("/user/dashboard/instantloan", {
          headers: {
            "Application-ID":
              "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserSessionId()}`,
          },
        });

        const data = await response.json();
        if (data.error) {
          throw new Error(
            `Error code ${data.error.code}: ${data.error.message}`
          );
        }
        setInstantActiveLoan(data.result);
        setLoading(false);
      } catch (error: any) {
        setError(error.message);
        setLoading(false);
      }
    };
    onFetchData();
  }, []);

  const instantLoanComponent = React.useMemo(() => {
    if (dashboardInfo) {
      if (dashboardInfo.status === "activeLoan") {
        if (dashboardInfo.data.length === 1) {
          const loan = dashboardInfo.data[0];
          if (loan.loanStatus === "active" || loan.loanStatus === "activePTP") {
            return <ActiveLoan loan={loan} />;
          }
          if (
            loan.loanStatus === "collection" ||
            loan.loanStatus === "collectionWaitingForAgreement"
          ) {
            return <Collection loan={loan} />;
          }
          if (loan.loanStatus === "activeTempoPayoff") {
            return <CanApply />;
          }
          if (loan.loanStatus === "waitingForPayoff") {
            return <WaitingPayoff loan={loan} />;
          }
        } else if (dashboardInfo.data.length > 1) {
          const orderLoans = dashboardInfo.data.sort((loanA, loanB) => {
            if (loanB.startDate == null) {
              return -1;
            }
            if (loanA.startDate == null) {
              return 1;
            }
            const dateB = new Date(loanB.startDate);
            const dateA = new Date(loanA.startDate);
            return dateA.getTime() - dateB.getTime();
          });

          const firstLoan = orderLoans[0];

          orderLoans.splice(0, 1);

          return (
            <>
              {firstLoan.loanStatus === "active" ||
              firstLoan.loanStatus === "activePTP" ? (
                <ActiveLoan
                  loan={firstLoan}
                  otherLoans={<OtherLoans data={orderLoans} />}
                />
              ) : null}
              {firstLoan.loanStatus === "collection" ||
              firstLoan.loanStatus === "collectionWaitingForAgreement" ? (
                <Collection
                  loan={firstLoan}
                  otherLoans={<OtherLoans data={orderLoans} />}
                />
              ) : null}
              {firstLoan.loanStatus === "waitingForPayoff" ? (
                <WaitingPayoff
                  loan={firstLoan}
                  otherLoans={<OtherLoans data={orderLoans} />}
                />
              ) : null}
            </>
          );
        }
      } else if (dashboardInfo.status === "activeApplication") {
        return <ActiveApplication />;
      } else if (dashboardInfo.status === "blacklisted") {
        return <Blacklisted />;
      } else if (dashboardInfo.status === "canApply") {
        return <CanApply isReloan={userData.isReloan} />;
      } else if (dashboardInfo.status === "manitobaCannotReApply") {
        return <ManitobaCannotReApply />;
      } else if (dashboardInfo.status === "pendingDisburse") {
        return <PendingDisburse {...dashboardInfo.data} />;
      } else if (dashboardInfo.status === "pendingSignature") {
        return <PendingSignature />;
      } else if (dashboardInfo.status === "preApproved") {
        return <PreApproved />;
      } else if (dashboardInfo.status === "provinceNotSupported") {
        return <ProvinceNotSupported />;
      }
      return <div>Error.</div>;
    }
    return <></>;
  }, [dashboardInfo]);

  if (loading)
    return (
      <div className="flex flex-col flex-grow-[1]">
        <CardBox>
          <DashboardSkeleton />
        </CardBox>
      </div>
    );
  if (!!error)
    return (
      <div className="flex flex-col flex-grow-[1]">
        <AlertBanner status="error">{error}</AlertBanner>
      </div>
    );

  if (!dashboardInfo) {
    return (
      <div className="flex flex-col flex-grow-[1]">
        <AlertBanner status="error">An unknown error occured.</AlertBanner>
      </div>
    );
  }

  return (
    <CardBox className="overflow-hidden min-h-[266px] sm:min-h-[140px]">
      <img
        src={iconInstant}
        alt="Instant loan"
        className="h-[266px] sm:h-[145px] absolute right-0 top-0 sm:top-[15px]"
      />
      {instantLoanComponent}
    </CardBox>
  );
};

const OtherLoans = (props: { data: InstantLoanDashboardActiveLoan[] }) => {
  let loanNb = props.data.length;

  const alertBannerStatus = React.useMemo(() => {
    let alertBannerStatus: "info" | "warning" | "error" | "valid" | undefined =
      "info";
    const collectionLoan = props.data.find(
      (loan) =>
        loan.loanStatus === "collection" ||
        loan.loanStatus === "collectionWaitingForAgreement"
    );
    const activeLoan = props.data.find(
      (loan) => loan.loanStatus === "active" || loan.loanStatus === "activePTP"
    );
    const tempoLoan = props.data.find(
      (loan) =>
        loan.loanStatus === "activeTempoPayoff" ||
        loan.loanStatus === "waitingForPayoff"
    );

    if (activeLoan) {
      alertBannerStatus = "info";
    }
    if (tempoLoan) {
      alertBannerStatus = "warning";
    }
    if (collectionLoan) {
      alertBannerStatus = "error";
    }
    return alertBannerStatus;
  }, [props.data]);

  const loans = React.useMemo(() => {
    const collectionsLoans = props.data.filter(
      (loan) =>
        loan.loanStatus === "collection" ||
        loan.loanStatus === "collectionWaitingForAgreement"
    );

    const payoffLoans = props.data.filter(
      (loan) =>
        loan.loanStatus === "activeTempoPayoff" ||
        loan.loanStatus === "waitingForPayoff"
    );

    const activeLoans = props.data.filter(
      (loan) => loan.loanStatus === "active" || loan.loanStatus === "activePTP"
    );
    return [...collectionsLoans, ...payoffLoans, ...activeLoans];
  }, [props.data]);

  const loanStatusConversion = (
    status:
      | "waitingForPayoff"
      | "activeTempoPayoff"
      | "collection"
      | "collectionWaitingForAgreement"
      | "active"
      | "activePTP"
  ) => {
    if (status === "active") {
      return "Active";
    } else if (status === "activePTP") {
      return "Active PTP";
    } else if (status === "activeTempoPayoff") {
      return "Temporary payoff";
    } else if (status === "collection") {
      return "Outstanding ⚠️ Action is needed";
    } else if (status === "collectionWaitingForAgreement") {
      return "Outstanding ⚠️ Action is needed";
    } else if (status === "waitingForPayoff") {
      return "Waiting for payoff";
    }
  };

  return (
    <>
      <div className="h-[0.5px] w-full bg-[#D2D2D2] mt-[27px] mb-[27px]"></div>
      <AlertSection status={alertBannerStatus} className="w-full">
        <h3 className="global--title mb-[24px]">Other loans</h3>
        <p className="text-interface-300 global--caption">
          You currently have {loanNb} more open instant loan. To get more
          information check the link below:
        </p>
        <ul className="list-inside mt-[11px]">
          {loans.map((loan, key: number) => (
            <li key={key} className="text-interface-300 global--caption">
              Loan #{loan.id}; State: {loanStatusConversion(loan.loanStatus)}
            </li>
          ))}
        </ul>
        <GatsbyLink
          inheritStyle
          className="global--link inline-block mt-[14px]"
          to="/dashboard/instant-loan/loan-history"
        >
          View all loans
        </GatsbyLink>
      </AlertSection>
    </>
  );
};

const CanApply = (props: LoanBasicsProps) => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan
    </h2>
    <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%]">
      {props.isReloan ? "Need a new loan?" : "Need a loan?"}
      <br />
      Start a new loan application now!
      <br />
      Our fast and easy loan application provides you an answer in just minutes!
    </p>
    <Button
      appearance="primary"
      onClick={() => {
        navigate("/dashboard/application-start");
      }}
      className="sm:px-[13px]"
    >
      {props.isReloan ? "Start application" : "Apply now"}
    </Button>
  </div>
);

const ActiveApplication = () => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan
    </h2>
    <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%]">
      Continue your loan application!
      <br />
      There are only a few steps remaining to finalize your loan application!
    </p>
    <Button
      appearance="primary"
      onClick={() => {
        navigate("/dashboard/application-start");
      }}
      className="sm:px-[13px]"
    >
      Continue your loan application
    </Button>
  </div>
);

const PreApproved = () => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan
    </h2>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%]">
      You have been pre-approved!
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%]">
      This means you are one step away from being approved for your loan.
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%]">
      We need to manually review the information you provided.
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%]">
      Once your loan has officially been approved, you will receive an email
      with a link that bring you back to your iCash.ca account to electronically
      sign your Agreement.
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%]">
      Our team is constantly revising loan loan application 7 days a week (6am -
      0am EST), so you should be receiving an answer shortly!
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%]">
      You will get a response within 2 hours during the week and on weekends, it
      could go up to 4 hours. (Business hours)
      <br />
    </p>
    <p className="global--paragraph text-interface-300 max-w-[70%]">
      If you have any questions, feel free to consult our{" "}
      <a
        className="text-primary hover:text-primary-emphasis font-500"
        href={"/faq"}
        target="_blank"
      >
        FAQ
      </a>
      .
    </p>
  </div>
);

const PendingSignature = () => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan
    </h2>
    <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%]">
      Congratulations!
      <br />
      Your loan application has been approved! Please take a moment to read and
      understand the terms of your loan agreement. Your loan agreement will
      expire on 2021-08-10 12:25. Please make sure to sign your loan agreement
      before it expires on 2021-08-10 12:25.
    </p>
    <Button
      appearance="primary"
      onClick={() => {
        navigate("/dashboard/application-contract");
      }}
      className="sm:px-[13px]"
    >
      Sign the contract
    </Button>
  </div>
);

const PendingDisburse = (props: PendingDisburseInstantLoan) => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan{" "}
      <span className="global--small-label ml-[5px]">#{props.id}</span>
    </h2>
    <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%]">
      If you have selected that you do not want to receive an e-transfer, we
      will send your funds by direct deposit. Please note that direct deposit
      are sent one time a day, business days, at 2pm and the reception of the
      funds can take up to one day.
    </p>
    <Button
      appearance="primary"
      onClick={() => {
        navigate("/dashboard/instant-loan");
      }}
      className="sm:px-[13px]"
    >
      Open dashboard
    </Button>
  </div>
);

const ActiveLoan = (props: {
  loan: ActiveInstantLoan;
  otherLoans?: JSX.Element;
}) => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan{" "}
      <span className="global--small-label ml-[5px]">#{props.loan.id}</span>
    </h2>
    <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%]">
      Your loan is active!
      <br />
      Check your payment progress on your Instant loan dashboard.
    </p>
    <div className="flex mb-[30px] sm:mb-[16px]">
      <div className="w-1/4 sm:w-1/2">
        <p className="global--label text-interface-300 mb-[14px] sm:w-1/2">
          Next payment amount
        </p>
        <Price
          amount={props.loan.nextPayment?.amount || 0}
          size="medium"
          className="mb-[38px]"
        />
      </div>
      <div className="w-1/4 sm:w-1/2">
        <p className="global--label text-interface-300 mb-[14px] sm:w-1/2">
          Next payment date
        </p>
        {props.loan.nextPayment?.dueDate == null ? (
          <p className="text-[31px] font-500 leading-[34px]">Not defined</p>
        ) : (
          getBigDateFormat(new Date(props.loan.nextPayment?.dueDate))
        )}
      </div>
    </div>
    <Button
      appearance="primary"
      onClick={() => {
        navigate("/dashboard/instant-loan");
      }}
      className="sm:px-[13px]"
    >
      Open dashboard
    </Button>
    {props.otherLoans ? props.otherLoans : null}
  </div>
);

const WaitingPayoff = (props: {
  loan: WaitingForPayoffInstantLoan;
  otherLoans?: JSX.Element;
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  return (
    <>
      <div className="relative">
        <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
          Instant Loan{" "}
          <span className="global--small-label ml-[5px]">#{props.loan.id}</span>
        </h2>
        <p className="global--paragraph text-interface-300 mb-[12px] sm:mb-[16px] max-w-[70%]">
          If you wish to reapply send us a proof of payment.
          <br />
          Your last payment has created your account and you cannot reapply?
          <br />
          Send a proof of payment for your last debited payment, 1 day after the
          payment date, to{" "}
          <a
            className="text-primary hover:text-primary-emphasis font-500"
            href={"mailto:documents@icash.ca"}
          >
            documents@icash.ca
          </a>
          .<br />
        </p>
        <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%]">
          Follow{" "}
          <span className="global--link" onClick={() => setIsOpen(true)}>
            these instructions
          </span>{" "}
          carefully to reapply.
        </p>
        <Button
          appearance="primary"
          onClick={() => {
            navigate("/dashboard/instant-loan");
          }}
          className="sm:px-[13px]"
        >
          Open dashboard
        </Button>
        {props.otherLoans ? props.otherLoans : null}
      </div>
      <SidePanel active={isOpen} onOpen={handleOpen} onClose={handleClose}>
        <Subheading>Follow these instructions to reapply</Subheading>
        <p className="global--paragraph mt-[44px]">
          Here's what a GOOD proof of payment looks like:
          <br />
          <b>Good proof of payment:</b>
          <br />
          <br />
          <a href={goodImg} target="_blank">
            <img
              src={goodImg}
              alt="Good"
              className="border-[2px] border-[#52a215]"
            />
          </a>
          <br />
          Make sure that:
          <ul className="list-none">
            <li>
              - We can see the iCash payment, the date of the payment, and your
              account balance IMMEDIATELY after the iCash loan payment
              withdrawal (NOT your current balance).
            </li>
            <li>
              - The document is added as an attachment and not copy-pasted in
              your email.
            </li>
            <li>
              - Send a proof of payment that respects the following criteria or
              it will be refused and you will have to wait the 4 business days
              to reapply.
            </li>
          </ul>
          <br />
          <b>BAD PROOF OF PAYMENT:</b>
          <br />
          <br />
          <a href={wrongImg} target="_blank">
            <img
              src={wrongImg}
              alt="Wrong"
              className="w-1/2 mx-auto border-[2px] border-[#f34747] sm:w-full"
            />
          </a>
          <br />
          <br />
        </p>
      </SidePanel>
    </>
  );
};

const ManitobaCannotReApply = () => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan
    </h2>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%]">
      Sorry, you have to wait to apply again.
      <br />
      Thank you for making your last payment. If you don't see the "reapply"
      button right away, it is because you need to wait 8 days from the date of
      your last payment before you can reapply, in accordance with Manitoba
      regulations.
      <br />
    </p>
    <p className="global--paragraph text-interface-300 mb-[12px] max-w-[70%]">
      You will be able to start your loan application 8 days after your last
      payment date.
    </p>
    <p className="global--small mb-[44px] italic sm:mb-[16px] max-w-[70%]">
      *If for any reason your payment will be returned, please let us know by
      email us at support us as{" "}
      <a
        className="text-primary hover:text-primary-emphasis font-500"
        href={"mailto:support@icash.ca"}
      >
        support@icash.ca
      </a>
    </p>
    <Button
      appearance="primary"
      onClick={() => {
        navigate("/dashboard/instant-loan");
      }}
      className="sm:px-[13px]"
    >
      Open dashboard
    </Button>
  </div>
);

const Collection = (props: {
  loan: CollectionInstantLoan;
  otherLoans?: JSX.Element;
}) => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan{" "}
      <span className="global--small-label ml-[5px]">#{props.loan.id}</span>
    </h2>
    <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%]">
      Sorry, you have to wait to apply again.
      <br />
      You already have an open loan with us!
      <br />
      We will be happy to provide you with another as soon as you have repaid
      your existing loan in full!
    </p>
    <AlertSection status="error">
      <h2 className="global--title mb-[18px]">Missed payment ⚠️</h2>
      <p className="global--caption text-interface-300 mb-[22px] max-w-[70%] sm:max-w-full">
        As of today, your personal loan has an outstanding balance of{" "}
        <b>
          {props.loan.collectionDetails.dueTotal?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </b>
        .
        <br />
        This amount is only valid until 0:00 EST on{" "}
        {dateFormatDay(props.loan.collectionDetails.validUntil ?? undefined)}.
        <br />
        To save time, money, and avoid collection communications, please take an
        action now.
      </p>
      <h3 className="global--paragraph font-600 mb-[28px]">
        Outstanding balance details
      </h3>
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-4 border-b-[1px] sm:border-b-[0px] border-b-[#D2D2D2] pb-[11px] sm:pb-[0px] mb-[22px] max-w-[70%] sm:max-w-full">
        <div className="">
          <p className="global--label text-interface-300 mb-[10px]">
            Of past due amount
          </p>
          <p className="text-[15px] font-700">
            {props.loan.collectionDetails.dueAmount?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
        <div className="">
          <p className="global--label text-interface-300 mb-[10px]">
            Of late interest
          </p>
          <p className="text-[15px] font-700">
            {props.loan.collectionDetails.dueLateInterest?.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )}
          </p>
        </div>
        <div className="border-r-[0.5px] sm:border-r-0 border-[#D2D2D2]">
          <p className="global--label text-interface-300 mb-[10px]">
            Of NSF fees
          </p>
          <p className="text-[15px] font-700">
            {props.loan.collectionDetails.dueNsf?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
        <div className="sm:border-t-[0.5px] border-[#D2D2D2] sm:w-[60%]">
          <p className="global--label text-interface-300 mb-[10px] sm:mt-[10px]">
            Total balance
          </p>
          <p className="text-[15px] font-700">
            {props.loan.collectionDetails.dueTotal?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
      </div>
      {props.loan.loanStatus === "collection" ? (
        <p className="text-interface-300 global--caption mb-[22px]">
          ⚠️ Please, contact us before{" "}
          {dateFormatDay(props.loan.collectionDetails.validUntil ?? undefined)}{" "}
          to save a late interest and NSF fees.
        </p>
      ) : (
        <p className="text-interface-300 global--caption mb-[22px]">
          Please, contact us to fix this issue.
        </p>
      )}
      <Button
        appearance="primary"
        onClick={() => {
          window.open("mailto:contact@icash.ca");
        }}
        className="sm:px-[13px]"
      >
        Contact us now
      </Button>
    </AlertSection>
    {props.otherLoans ? props.otherLoans : null}
  </div>
);

const ProvinceNotSupported = () => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan
    </h2>
    <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%]">
      Coming soon!
      <br />
      Unfortunately, iCash is not available in your province yet!
      <br />
      We are working on it and we will inform you as soon as iCash becomes
      available in your province!
    </p>
  </div>
);

const Blacklisted = () => (
  <div className="relative">
    <h2 className="global--small-title text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
      Instant Loan
    </h2>
    <p className="global--paragraph text-interface-300 mb-[44px] sm:mb-[16px] max-w-[70%]">
      Unfortunately we cannot offer you a loan at this time, but you can still
      access the information of your previous loans.
    </p>
  </div>
);
