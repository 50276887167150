import * as React from "react";
import {
  AlertBanner,
  CardBox,
  DashboardSkeleton,
} from "@brainfinance/icash-component-library";

import { LoansFactory } from "@components/loan-history-cards/components";
import { getUserSessionId } from "@helpers/auth";
import { mariaFetch } from "@helpers/maria-fetch";

export const InstantLoanHistory = () => {
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loanHistory, setLoanHistory] = React.useState<any[]>([]);

  React.useEffect(() => {
    // Loan history
    mariaFetch("/loan/list", {
      method: "GET",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        Authorization: `Bearer ${getUserSessionId()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          throw new Error(
            `Error code ${data.error.code}: ${data.error.message}`
          );
        }
        setLoanHistory(data.result);
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <div className="flex flex-col flex-grow-[1]">
        <CardBox>
          <DashboardSkeleton />
        </CardBox>
      </div>
    );
  if (!!error)
    return (
      <div className="flex flex-col flex-grow-[1]">
        <AlertBanner status="error">{error}</AlertBanner>
      </div>
    );

  return <LoansFactory loans={loanHistory.reverse()} />;
};
