import { Modal } from "@brainfinance/icash-component-library";
import { formatPhoneNumberWithCountryCode } from "@helpers/phoneNumber";
import * as React from "react";
import { ConcatLoanType, LoanFactoryPropsType } from "../types";
import { ActiveLoanComponent } from "./active-loan";
import { CollectionLoanComponent } from "./collection-loan";
import { EndLoanComponent } from "./end-loan";
import { TempoLoanComponent } from "./tempo-loan";

export const LoansFactory = (props: LoanFactoryPropsType) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const [contact, setContact] =
    React.useState<{ contactEmail: string; contactPhoneNumber: string }>();

  const loansPriorities = React.useMemo<{
    priorityOne: ConcatLoanType;
    priorityTwo: ConcatLoanType;
    priorityThree: ConcatLoanType;
  }>(() => {
    const priorityOne = props.loans.filter(
      (loan) => loan.loanStatus === "active" || loan.loanStatus === "activePTP"
    );
    const priorityTwo = props.loans.filter(
      (loan) =>
        loan.loanStatus === "collection" ||
        loan.loanStatus === "collectionWaitingForAgreement" ||
        loan.loanStatus === "activeTempoPayoff" ||
        loan.loanStatus === "waitingForPayoff"
    );
    const priorityThree = props.loans.filter(
      (loan) =>
        loan.loanStatus === "cancelled" || loan.loanStatus === "finished"
    );

    return {
      priorityOne,
      priorityTwo,
      priorityThree,
    };
  }, [props.loans]);

  const onContactUsCallback = (
    contactEmail: string,
    contactPhoneNumber: string
  ) => {
    setContact({
      contactEmail,
      contactPhoneNumber,
    });
    handleOpen();
  };

  const onDisplayLoans = (loans: ConcatLoanType) => {
    return loans.map((loan) => {
      if (loan.loanStatus === "active" || loan.loanStatus === "activePTP") {
        return <ActiveLoanComponent key={loan.id} {...loan} />;
      } else if (
        loan.loanStatus === "cancelled" ||
        loan.loanStatus === "finished"
      ) {
        return <EndLoanComponent key={loan.id} {...loan} />;
      } else if (
        loan.loanStatus === "collection" ||
        loan.loanStatus === "collectionWaitingForAgreement"
      ) {
        return (
          <CollectionLoanComponent
            key={loan.id}
            loan={loan}
            onContactUsCallback={onContactUsCallback}
          />
        );
      } else if (
        loan.loanStatus === "activeTempoPayoff" ||
        loan.loanStatus === "waitingForPayoff"
      ) {
        return <TempoLoanComponent key={loan.id} {...loan} />;
      } else {
        return <div>UNKNOWN</div>;
      }
    });
  };

  return (
    <>
      <div className="flex flex-col flex-grow-[1]">
        {onDisplayLoans(loansPriorities.priorityOne)}
        {onDisplayLoans(loansPriorities.priorityTwo)}
        {(loansPriorities.priorityOne.length > 0 ||
          loansPriorities.priorityTwo.length > 0) &&
          loansPriorities.priorityThree.length > 0 && (
            <div className="first:hidden border-b-[1px] border-b-[#D2D2D2] mb-[33px] pb-[11px]"></div>
          )}
        {loansPriorities.priorityThree.length > 0 && (
          <div className="flex flex-wrap gap-[22px]">
            {onDisplayLoans(loansPriorities.priorityThree)}
          </div>
        )}
      </div>
      {isOpen && (
        <Modal
          onClose={() => setIsOpen(false)}
          className="min-h-min min-w-[600px] sm:min-h-0 sm:min-w-0"
        >
          <div className="modal--content mt-[44px]">
            <p className="global--label text-interface-500 text-[20px] font-600 mb-[20px]">
              Update your bank account
            </p>
            <p className="global--label text-interface-300 mb-[20px]">
              Use one of those two options to contact us today:
            </p>
            <div className="flex flex-1 w-full flex-row">
              <div className="flex-1">
                <p className="global--label text-interface-300 font-600 mb-[10px]">
                  Email at:
                </p>
                <a
                  href={`mailto:${contact?.contactEmail}`}
                  className="text-[15px] font-600 text-primary"
                >
                  {contact?.contactEmail}
                </a>
              </div>
              <div className="flex-1">
                <p className="global--label text-interface-300 font-600 mb-[10px]">
                  Call at:
                </p>
                <a
                  href={`tel:${contact?.contactPhoneNumber}`}
                  className="text-[15px] font-600 text-primary"
                >
                  {formatPhoneNumberWithCountryCode(
                    contact?.contactPhoneNumber
                  )}
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
