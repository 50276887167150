import * as React from "react";
import { Router } from "@reach/router";

import {
  IndexDashboardPage,
  InstantLoanPage,
  ReferralProgramPage,
  StartAppPage,
  LoyaltyProgramPage,
  WalletPage,
  ApplicationContractPage,
  ChangeEmail,
  EmailVerificationSent,
  UserProfile,
  RepaymentPlanPage,
} from "@dashboard";
import { AuthRoute } from "@containers/dashboard";

import { UserType, UserContext } from "@helpers/user";
import { getUserSessionId } from "@helpers/auth";
import { mariaFetch } from "@helpers/maria-fetch";

function parseErrorMessage(error: { code?: string | number; message: string }) {
  const errorIntro = error.code ? `Error code ${error.code}` : "Error";
  return `${errorIntro}: ${error.message}`;
}

const Dashboard = () => {
  const [userData, setUserData] = React.useState<UserType>({});
  const [userLoading, setUserLoading] = React.useState<boolean>(true);
  const [userError, setUserError] = React.useState<string>();

  React.useEffect(() => {
    mariaFetch("/user/info", {
      method: "GET",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        Authorization: `Bearer ${getUserSessionId()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) throw new Error(parseErrorMessage(data.error));
        else setUserError(undefined);
        setUserData(data.result);
      })
      .catch((e) => setUserError(e.message))
      .finally(() => setUserLoading(false));
  }, []);

  return (
    <UserContext.Provider value={{ userData, userLoading, userError }}>
      <Router basepath="/dashboard">
        {/* Protected Routes will go here */}

        <AuthRoute path="/profile/" component={UserProfile} />
        <AuthRoute path="/profile/change-email" component={ChangeEmail} />
        <AuthRoute
          path="/profile/email-verification-sent"
          component={EmailVerificationSent}
        />

        <AuthRoute
          path="/application-contract"
          component={ApplicationContractPage}
        />
        <AuthRoute path="/repayment-plan" component={RepaymentPlanPage} />
        <AuthRoute path="/referral-program" component={ReferralProgramPage} />
        <AuthRoute path="/application-start" component={StartAppPage} />
        <AuthRoute path="/instant-loan" component={InstantLoanPage} />
        <AuthRoute path="/instant-loan/:tabId" component={InstantLoanPage} />
        <AuthRoute path="/loyalty-program" component={LoyaltyProgramPage} />
        <AuthRoute path="/wallet" component={WalletPage} />
        <AuthRoute path="/" component={IndexDashboardPage} />
      </Router>
    </UserContext.Provider>
  );
};

export default Dashboard;
