import * as React from "react";
import { navigate } from "gatsby";
import {
  Button,
  DashboardSkeleton,
  Modal,
} from "@brainfinance/icash-component-library";

import { DashboardContainerProps } from "./types";
import { DashboardLayout } from "@components/dashboard-layout";
import { UserContext } from "@helpers/user";
import { removeLocalStorageUserSession } from "@helpers/auth";

function getMembership(nbLoans?: number) {
  if (typeof nbLoans === "number") {
    if (nbLoans >= 20) return "VIP";
    if (nbLoans >= 10) return "Prestige";
  }
  return "Elite";
}

export const DashboardContainer = (
  props: React.PropsWithChildren<DashboardContainerProps>
) => {
  const { userData, userLoading, userError } = React.useContext(UserContext);

  const redirectToLogin = () => {
    removeLocalStorageUserSession();
    navigate("/login");
  };

  if (userLoading)
    return (
      <DashboardLayout userEmail="" userMembership="">
        <div className="w-full p-[44px] md:p-[22px] sm:p-[11px]"></div>
      </DashboardLayout>
    );

  if (!!userError)
    return (
      <DashboardLayout userEmail="" userMembership="">
        <div className="w-full p-[44px] md:p-[22px] sm:p-[11px]">
          <DashboardSkeleton />
        </div>
        <Modal
          header={
            <div className="text-[20px] font-600 tracking-[-0.5px]">
              Session expired
            </div>
          }
          footer={
            <div className="flex flex-col items-stretch w-full min-h-[152px]">
              <div className="flex-1 p-[22px] global--paragraph">
                {userError || "An error has occured."}
              </div>
              <Button
                className="w-full"
                appearance="primary"
                onClick={redirectToLogin}
              >
                Log out
              </Button>
            </div>
          }
          onClose={redirectToLogin}
        />
      </DashboardLayout>
    );

  return (
    <DashboardLayout
      userEmail={userData.email || ""}
      userMembership={getMembership(userData.nbLoans)}
      bodyClass={props.bodyClass}
    >
      {props.children}
    </DashboardLayout>
  );
};
