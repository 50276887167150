import * as React from "react";
import { DashboardContainer } from "@containers/dashboard";
import { getUserSessionId } from "@helpers/auth";
import { logBranchEvent } from "@helpers/branch";
import { AlertBanner, SidePanel } from "@brainfinance/icash-component-library";
import { ProvinceLicense } from "@containers/province-license";
import { provinceDict } from "@helpers/provinceConversion";

export const StartAppPage = () => {
  const [iFrameReady, setFrameReady] = React.useState(false);
  const [iFrameSidePanelReady, setFrameSidePanelReady] = React.useState(false);
  const [sidePanelRoute, setSidePanelRoute] = React.useState<string>();
  const [applicationError, setApplicationError] =
    React.useState<string | undefined>();
  const iFrameRef = React.useRef<HTMLIFrameElement>(null);
  const iFrameSidePanelRef = React.useRef<HTMLIFrameElement | null>(null);
  const [height, setHeight] = React.useState<number>(100);
  const [provinceLicenseInformation, setProvinceLicenseInformation] =
    React.useState<string>();
  const [isProvinceOpen, setIsProvinceOpen] = React.useState(false);

  const handleProvinceOpen = () => setIsProvinceOpen(true);
  const handleProvinceClose = () => setIsProvinceOpen(false);

  React.useEffect(() => {
    const listenToFrame = (e: MessageEvent<any>) => {
      if (e.data.event === "error") {
        setApplicationError(e.data.error.message);
        return;
      }

      if (iFrameSidePanelRef.current !== null && e.data.event === "ready") {
        setFrameSidePanelReady(true);
      }

      if (e.data.event === "ready") setFrameReady(true);
      else if (e.data.event === "branch")
        logBranchEvent(e.data.payload.eventName, e.data.payload.metadata);
      else if (e.data.event === "size") setHeight(e.data.payload.height);
      else if (e.data.event === "licence-information-side-panel") {
        setProvinceLicenseInformation(
          provinceDict[e.data.payload.provinceCode]
        );
        handleProvinceOpen();
      } else if (e.data.event === "repayment-options-side-panel")
        setSidePanelRoute(e.data.payload.route);
      else console.log(e.data);
    };
    window.addEventListener("message", listenToFrame);
    return () => window.removeEventListener("message", listenToFrame);
  }, []);

  React.useEffect(() => {
    if (iFrameReady && iFrameRef.current) {
      iFrameRef.current.contentWindow?.postMessage(
        { token: getUserSessionId() },
        "*"
      );
    }
  }, [iFrameReady, iFrameRef]);

  React.useEffect(() => {
    if (iFrameSidePanelRef.current && iFrameSidePanelReady === true) {
      iFrameSidePanelRef.current.contentWindow?.postMessage(
        { token: getUserSessionId(), route: sidePanelRoute },
        "*"
      );
    }
  }, [iFrameSidePanelRef, iFrameSidePanelReady, sidePanelRoute]);

  return (
    <>
      <DashboardContainer bodyClass="application">
        <div className="w-full h-full relative sm:min-w-screen sm:min-h-[calc(100vh-59px)]">
          {applicationError ? (
            <AlertBanner className="absolute z-10" status="error">
              {applicationError}
            </AlertBanner>
          ) : null}
          <iframe
            className="min-h-full"
            src={process.env.GATSBY_SELENE_URL}
            ref={iFrameRef}
            id="il"
            width="100%"
            height={height}
          ></iframe>
        </div>
      </DashboardContainer>
      <SidePanel
        active={sidePanelRoute !== undefined}
        onClose={() => {
          setSidePanelRoute(undefined);
          iFrameSidePanelRef.current = null;
          setFrameSidePanelReady(false);
        }}
      >
        <div className="w-[720px] sm:w-full h-full">
          {sidePanelRoute && (
            <iframe
              src={process.env.GATSBY_SELENE_URL}
              ref={iFrameSidePanelRef}
              id="il-side-panel"
              width="100%"
              height="100%"
            ></iframe>
          )}
        </div>
      </SidePanel>

      <SidePanel
        active={isProvinceOpen}
        onOpen={handleProvinceOpen}
        onClose={handleProvinceClose}
      >
        <div className="flex flex-1 flex-col h-full">
          <ProvinceLicense
            province={provinceLicenseInformation}
            onPressContinue={handleProvinceClose}
          />
        </div>
      </SidePanel>
    </>
  );
};
