import tw from "tailwind-styled-components"

export const DefaultHeader = tw.div`
  h-full
  relative
  max-h-[155px]
  border-b-[1px]
  border-b-interface-100
  bg-interface-100

  before:content-[""]
  before:w-full
  before:h-[5px]
  before:absolute
  before:top-0
  before:left-0
  before:opacity-50
  before:bg-[linear-gradient(135deg,_#FFBC01_1%,_#FE5368_16.45%,_#00A4FF_51.05%,_#1EC198_65.57%,_#F88CFD_79.41%,_#F94261_100%)]

  sm:before:hidden
`
