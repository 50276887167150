import * as React from "react";
import { ActiveLoanType } from "../types";

import {
  AlertSection,
  CardBox,
  Price,
  SimpleProgressBar,
} from "@brainfinance/icash-component-library";

import { ContractDownloadLink } from "@components/contract-download/components";
import { dateFormatDay, dateToYMD } from "../utilites/dateFormat";

export const ActiveLoanComponent = (props: ActiveLoanType) => {
  const getNextPaymentSource = (id: number | undefined) => {
    const nextPayment = props.payments?.find((tmp) => tmp.id === id);
    if (nextPayment && nextPayment.method === "eft") {
      return "Checking " + nextPayment.bankAccount?.accountNumber ?? "";
    }
    if (nextPayment && nextPayment.method === "etransfer") {
      return "Etransfer";
    }

    const payment = props.payments?.find(
      (payment) => payment.status === "Pending"
    );

    if (payment && payment.method === "eft") {
      return "Checking " + payment.bankAccount?.accountNumber ?? "";
    }
    if (payment && payment.method === "etransfer") {
      return "Etransfer";
    }

    return "";
  };

  const getNextPaymentAmount = () => {
    if (props.nextPayment) {
      return props.nextPayment.amount;
    } else {
      const payment = props.payments?.find(
        (payment) => payment.status === "Pending"
      );
      if (payment) {
        return payment.amount;
      }
    }
    return undefined;
  };

  const getNextPaymentDate = () => {
    if (props.nextPayment) {
      return props.nextPayment.dueDate;
    } else {
      const payment = props.payments?.find(
        (payment) => payment.status === "Pending"
      );
      if (payment) {
        return payment.dueDate;
      }
    }
    return undefined;
  };

  return (
    <CardBox key={props.id}>
      <h2 className="global--title mb-[40px] pt-[3px]">Loan #{props.id}</h2>
      {props.loanStatus === "activePTP" ? (
        <div className="grid grid-cols-2 sm:grid-cols-1 border-b-[1px] border-b-[#D2D2D2] gap-[30px] pb-[33px] mb-[33px]">
          <div className="">
            <p className="global--label text-interface-300 mb-[12px]">
              Paid amount
            </p>
            <Price
              amount={props.paidAmount || 0}
              integer
              size="medium"
              className="mb-[18px]"
            />
            <SimpleProgressBar
              completed={
                props.payments?.filter((item: any) => item.status === "Paid")
                  .length || 0
              }
              goal={(props.payments ?? []).length}
              unit="repayments"
            />
          </div>
          <div className="grid grid-cols-2">
            <div>
              <p className="global--label text-interface-300 mb-[10px]">
                Next payment
              </p>
              <p className="text-[15px] font-700 mb-[22px]">
                {getNextPaymentAmount()?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </div>
            <div className="">
              <p className="global--label text-interface-300 mb-[10px]">
                Source
              </p>
              <p className="text-[15px] font-700">
                {getNextPaymentSource(props.nextPayment?.id)}
              </p>
            </div>
            <div>
              <p className="global--label text-interface-300 mb-[10px]">
                Payment date
              </p>
              <p className="text-[15px] font-700">
                {dateFormatDay(getNextPaymentDate())}
              </p>
            </div>
          </div>
        </div>
      ) : null}
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-[30px]">
        <div className="grid grid-cols-2 border-r-[0.5px] sm:border-r-0 border-[#D2D2D2]">
          <AlertSection status="info">
            <div className="w-full relative">
              <p className="global--label text-interface-300 mb-[10px]">
                State
              </p>
              <p className="text-[15px] font-700 mb-[22px]">
                {props.loanStatus === "active" ? "Active" : "Active PTP"}
              </p>
            </div>
          </AlertSection>
          <div>
            <p className="global--label text-interface-300 mb-[10px]">
              Loan amount
            </p>
            <p className="text-[15px] font-700 mb-[22px]">
              {props.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
          <div>
            <p className="global--label text-interface-300 mb-[10px]">
              Start date
            </p>
            <p className="text-[15px] font-700">{dateToYMD(props.startDate)}</p>
          </div>
          <div>
            <p className="global--label text-interface-300 mb-[10px]">
              End date
            </p>
            <p className="text-[15px] font-700">{dateToYMD(props.endDate)}</p>
          </div>
        </div>
        {props.agreementId !== undefined &&
        props.padDocumentId !== undefined ? (
          <div className="sm:border-t-[0.5px] border-[#D2D2D2]">
            <p className="global--label text-interface-300 mb-[10px] sm:mt-[20px]">
              Documents
            </p>
            <ContractDownloadLink
              title="Loan contract"
              contractId={props.agreementId}
            />
            <ContractDownloadLink
              title="Loan PAD"
              contractId={props.padDocumentId}
            />
          </div>
        ) : null}
      </div>
    </CardBox>
  );
};
