import * as React from "react";
import { CardBox, Tab, Tabs } from "@brainfinance/icash-component-library";
import { ReferralListProps } from "./referral-list-props";
import { getFormatedDate } from "@helpers/dateFormat";

export const ReferralListTabs = (props: ReferralListProps) => {

  const tabs = [
    {
      id: "referral-success",
      name: "Success",
      content:
        props.activeRef &&
        props.data.referrals?.filter((state: any) => state.status === "Success").length ? (
          props.data.referrals
            ?.filter((state: any) => state.status === "Success")
            .map((referral: any, key: number) => (
              <CardBox key={key} className="bg-transparent border border-interface-150 py-[20px]">
                <div className="flex">
                  <p className="global--caption font-500 text-interface-300 w-3/4">
                    {getFormatedDate(new Date(referral.applicationCreationDate), "long", "long", true)}
                  </p>
                  <p className="global--caption font-500 text-interface-300 w-1/4">${referral.amount}</p>
                </div>
              </CardBox>
            ))
        ) : (
          <p className="global--caption text-interface-300">
            You do not have any referred friends who have paid off their loans.
          </p>
        )
    },
    {
      id: "referral-pending",
      name: "Pending",
      content:
        props.data.referrals?.filter((state: any) => state.status === "Pending").length ? (
          props.data.referrals
            ?.filter((state: any) => state.status === "Pending")
            .map((referral: any, key: number) => (
              <CardBox key={key} className="bg-transparent border border-interface-150 py-[20px]">
                <p className="global--caption font-500 text-interface-300 w-3/4">
                  {getFormatedDate(new Date(referral.applicationCreationDate), "long", "long", true)}
                </p>
              </CardBox>
            ))
        ) : (
          <p className="global--caption text-interface-300">
            Currently, you do not have any referred friends who have pending applications, pending contract signatures,
            pending disburse, payment plan active, or temporary pay-off loans.
          </p>
        )
    },
    {
      id: "referral-inactive",
      name: "Inactive",
      content:
        props.data.referrals?.filter((state: any) => state.status === "Inactive").length ? (
          props.data.referrals
            ?.filter((state: any) => state.status === "Inactive")
            .map((referral: any, key: number) => (
              <CardBox key={key} className="bg-transparent border border-interface-150 py-[20px]">
                <p className="global--caption font-500 text-interface-300 w-3/4">
                  {getFormatedDate(new Date(referral.applicationCreationDate), "long", "long", true)}
                </p>
              </CardBox>
            ))
        ) : (
          <p className="global--caption text-interface-300">
            You do not have any referred friends who have loans paid after the defined end date, written off, cancelled
            loans or loans with early repayment.
          </p>
        )
    }
  ];

  const [selectedTabId, setSelectedTabId] = React.useState(tabs[0].id);
  const selectedTabContent = React.useMemo(
    () => tabs.find((tab) => tab.id === selectedTabId)?.content,
    [selectedTabId, props.data]
  );

  return (
    <CardBox className="max-h-[480px] overflow-hidden">
      <h2 className="global--title mb-[24px] pt-[3px]">Track your referrals</h2>
      <Tabs size="small">
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            onClick={() => setSelectedTabId(tab.id)}
            isSelected={selectedTabId === tab.id}
            className="first:ml-[24px]"
          >
            {tab.name}
          </Tab>
        ))}
      </Tabs>
      <div className="mt-[26px] max-h-[324px]">{selectedTabContent}</div>
    </CardBox>
  );
};
