export function dateToYMD(dateStr: string | undefined) {
  if (!dateStr) {
    return "";
  }
  const date = new Date(dateStr);
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getFullYear();
  return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}

const getOrdinalNumber = (day: string) => {
  if (day === "1") {
    return day + "st";
  } else if (day === "2") {
    return day + "nd";
  } else if (day === "3") {
    return day + "rd";
  } else {
    return day + "th";
  }
};

export function dateFormatDay(dateStr: string | undefined) {
  if (!dateStr) {
    return "";
  }

  let date = new Date(dateStr.replace(/-/g, "/")).toLocaleDateString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "long",
  });
  if (date === "Invalid Date") {
    date = new Date(dateStr).toLocaleDateString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "long",
    });
  }

  const splited = date.split(" ");
  if (splited.length === 3) {
    return splited[0] + " " + getOrdinalNumber(splited[2]) + " " + splited[1];
  }
  return "";
}
