import * as React from "react";

const getDateNth = function (date: number) {
  if (date > 3 && date < 21) return `${date}th`;
  switch (date % 10) {
    case 1:
      return `${date}st`;
    case 2:
      return `${date}nd`;
    case 3:
      return `${date}rd`;
    default:
      return `${date}th`;
  }
};

export const getFormatedDate = (
  date: Date,
  day?: "short" | "long" | "narrow",
  month?: "short" | "long" | "narrow",
  year?: boolean
) =>
  `${date.toLocaleDateString("en-US", {
    weekday: day || "short",
  })}, ${getDateNth(date.getDate())} ${date.toLocaleDateString("en-US", {
    month: month || "long",
  })} ${
    year
      ? date.toLocaleDateString("en-US", {
          year: "numeric",
        })
      : ""
  }`;

const getDateNthFormated = function (date: number) {
  if (date > 3 && date < 21) return `${date}th`;
  switch (date % 10) {
    case 1:
      return (
        <>
          {date}
          <span className="text-[20px]">st</span>
        </>
      );
    case 2:
      return (
        <>
          {date}
          <span className="text-[20px]">nd</span>
        </>
      );
    case 3:
      return (
        <>
          {date}
          <span className="text-[20px]">rd</span>
        </>
      );
    default:
      return (
        <>
          {date}
          <span className="text-[20px]">th</span>
        </>
      );
  }
};

export const getBigDateFormat = (date: Date) => (
  <p className="text-[31px] font-500 leading-[34px]">
    {date.toLocaleDateString("en-US", { weekday: "short" })},{" "}
    {getDateNthFormated(date.getDate())}
    <span className="text-[14px] leading-[22px] block">
      {date.toLocaleDateString("en-US", { month: "long" })}
    </span>
  </p>
);

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date: Date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-") +
    " " +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(":")
  );
}
