import * as React from "react";
import { DashboardContainer, ReferralProgram } from "@containers/dashboard";
import { DefaultHeader } from "../instant-loan-page/content-header.style";
import { Subheading } from "@components/typography";

export const ReferralProgramPage = () => (
  <DashboardContainer>
    <DefaultHeader className="!max-h-[120px]">
      <Subheading className="p-[44px]">Referral Program</Subheading>
    </DefaultHeader>
    <ReferralProgram />
  </DashboardContainer>
);