import { DashboardSkeleton } from "@brainfinance/icash-component-library";
import { mariaFetch } from "@helpers/maria-fetch";
import * as React from "react";
import { FaqHandlerProps, FaqInfo } from "./faq-handler";

export const FaqHandler = (props: FaqHandlerProps) => {
  const [faqInfo, setFaqInfo] = React.useState<FaqInfo>();

  const fetchSection = async (section: string) => {
    try {
      const res = await mariaFetch(`/quickfaq/section/${section}`, {
        method: "GET",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        const data = (await res.json()) as { result: FaqInfo };
        setFaqInfo(data.result);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchSection(props.section);
  }, [props.section]);

  if (!faqInfo) {
    return <DashboardSkeleton />;
  }

  return props.onFetchFaq(faqInfo);
};
