import * as React from "react";
import { DashboardContainer } from "@containers/dashboard";
import { UserContext } from "@helpers/user";
import { navigate } from "gatsby";
import { getUserSessionId } from "@helpers/auth";

type ResponseType =
  | {
      status: "error";
      data: {
        code: number;
        message: string;
      };
    }
  | {
      status: "ready";
      data: {
        message: string;
      };
    }
  | {
      status: "branch";
      data: {
        eventName: string;
      };
    }
  | {
      status: "end";
      data: {
        reason: "Bank connection error.";
      };
    };

export type RequestMessage = {
  status: "info";
  data: {
    token: string;
    applicationId: string;
    userId: string;
    loanId: string;
  };
};

export const RepaymentPlanPage = () => {
  const [iFrameReady, setFrameReady] = React.useState(false);
  const iFrameRef = React.useRef<HTMLIFrameElement>(null);
  const { userData } = React.useContext(UserContext);

  React.useEffect(() => {
    const listenToFrame = (e: MessageEvent<ResponseType>) => {
      const data = e.data;

      if (e.source !== iFrameRef.current?.contentWindow) {
        return;
      }

      if (data.status === "ready") {
        setFrameReady(true);
      } else if (data.status === "end") {
        console.warn(data.data.reason);
        navigate("/dashboard");
      } else if (data.status === "error") {
        console.error(data);
      }
    };
    window.addEventListener("message", listenToFrame);
    return () => window.removeEventListener("message", listenToFrame);
  }, []);

  React.useEffect(() => {
    if (iFrameReady && iFrameRef.current) {
      const state = window.history.state as { loanId?: number };
      if (state.loanId === undefined) {
        navigate("/dashboard");
        return;
      }

      iFrameRef.current.contentWindow?.postMessage(
        {
          status: "info",
          data: {
            loanId: state.loanId?.toString(),
            userId: userData.contactId?.toString(),
            token: getUserSessionId(),
            applicationId: "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          },
        } as RequestMessage,
        "*"
      );
    }
  }, [iFrameReady, iFrameRef]);

  return (
    <>
      <DashboardContainer bodyClass="application">
        <div className="w-full h-full">
          <iframe
            ref={iFrameRef}
            id="il"
            src={process.env.GATSBY_REPAYMENT_PLAN_URL}
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </DashboardContainer>
    </>
  );
};
