import * as React from "react";
import { navigate } from "gatsby";
import { DashboardContainer } from "@containers/dashboard";
import {
  ConfettiModal,
  Tab,
  Tabs,
} from "@brainfinance/icash-component-library";
import { InstantLoanActive } from "@containers/dashboard/instant-loan";
import { InstantLoanCashback } from "@containers/dashboard/instant-loan/instant-loan-cashback";
import { InstantLoanHistory } from "@containers/dashboard/instant-loan/instant-loan-history";
import { DefaultHeader } from "./content-header.style";
import { Subheading } from "@components/typography";
import { InstantLoanSettings } from "@containers/dashboard/instant-loan/instant-loan-settings";
import { getUserSessionId } from "@helpers/auth";
import { UserContext } from "@helpers/user";
import { instantLoanDashboardAccesible } from "@helpers/dashboardNavigationConditions";
import { useLocation } from "@reach/router";
import { mariaFetch } from "@helpers/maria-fetch";

const tabs = [
  {
    id: "active-loan",
    name: "Active loan",
    content: <InstantLoanActive />,
  },
  {
    id: "start-loan",
    name: "Start a loan",
    content: <InstantLoanActive />,
  },
  {
    id: "loan-history",
    name: "Loan history",
    content: <InstantLoanHistory />,
  },
  {
    id: "cashback",
    name: "Cashback",
    content: <InstantLoanCashback />,
  },
  {
    id: "settings",
    name: "Settings",
    content: <InstantLoanSettings />,
  },
];

const getTabs = (status: string, provinceId: number) => {
  if (provinceId !== 6) {
    // Check if not Manitoba
    if (status === "canApply") return [tabs[1], tabs[2], tabs[3], tabs[4]];
    if (status === "blacklisted" || status === "provinceNotSupported")
      return [tabs[2], tabs[3], tabs[4]];
    return [tabs[0], tabs[2], tabs[3], tabs[4]];
  } else {
    if (status === "canApply") return [tabs[1], tabs[2], tabs[4]];
    if (status === "blacklisted" || status === "provinceNotSupported")
      return [tabs[2], tabs[4]];
    return [tabs[0], tabs[2], tabs[4]];
  }
};

export const InstantLoanPage = (props: { tabId?: string }) => {
  const { userData, userLoading } = React.useContext(UserContext);
  const [availableTabs, setAvailableTabs] = React.useState<typeof tabs>([]);
  const location = useLocation();
  const [newLoanBegin, setNewLoanBegin] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!userLoading) {
      if (!instantLoanDashboardAccesible(userData)) {
        navigate("/dashboard");
        return;
      }

      mariaFetch("/user/dashboard", {
        method: "GET",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setAvailableTabs(
            getTabs(data.result.status, data.result.data.addressStateId)
          );
        });
    }
  }, [userData, userLoading]);

  React.useEffect(() => {
    if (availableTabs[0] && !props.tabId) {
      navigate(`./${availableTabs[0].id}`);
    }
  }, [availableTabs]);

  const selectedTabContent = React.useMemo(
    () => availableTabs.find((tab) => tab.id === props.tabId)?.content,
    [availableTabs, props.tabId]
  );

  React.useEffect(() => {
    const state = location.state as { loanBegin?: boolean };

    if (state?.loanBegin === true) {
      setNewLoanBegin(true);
    }
  }, [location]);

  return (
    <DashboardContainer>
      <DefaultHeader>
        <Subheading className="p-[44px] pb-[37px]">Instant Loan</Subheading>
        <Tabs>
          {availableTabs.map((tab) => (
            <Tab
              key={tab.id}
              onClick={() => navigate(`../${tab.id}`)}
              isSelected={props.tabId === tab.id}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
      </DefaultHeader>
      <div className="m-[44px] flex gap-[22px] max-w-[1050px] flex-wrap md:m-[22px] sm:m-[11px]">
        {selectedTabContent}
      </div>
      {newLoanBegin && (
        <ConfettiModal
          onClose={() => setNewLoanBegin(false)}
          className="w-[40%] sm:w-[80%] sm:max-w-full sm:min-w-[80%] sm:min-h-[80%] sm:max-h-[80%]"
        >
          <div className="p-[44px] sm:p-[22px]">
            <h3 className="text-[26px] leading-[33px] tracking-[-0.5px] text-[#171616] font-600 mb-[44px]">
              Loan has begun
            </h3>
            <div className="font-400 text-[15px] leading-[20px] tracking-[-0.5px] text-[#13141A]">
              <p className="mb-[22px]">
                Congratulations! Your Instant loan has begun!
              </p>
              <p className="mb-[22px]">
                You will receive your funds by e-transfer in the next 5 minutes!
              </p>
            </div>
            <p className="font-400 text-[13px] leading-[18px] tracking-[-0.15px] text-[#515657]">
              If you have selected that you do not want to receive an
              e-transfer, we will send your funds by direct deposit. Please note
              that direct deposit are sent one time a day, business days, at 2pm
              and the reception of the funds can take up to one day.
            </p>
            <div className="w-full h-[0.5px] mt-[22px] mb-[22px] bg-[#e5e7eb]"></div>
            <div>
              <p className="font-600 text-[13px] leading-[18px] tracking-[-0.15px] text-[#6F7375]">
                Repaying in advance by e-transfer
              </p>
              <p className="font-400 text-[13px] leading-[18px] mt-[22px] tracking-[-0.15px] text-[#515657]">
                You are entitled to repay this loan in advance at any time
                without any penalty fees added. Contact us to validate if you
                are eligible to repay by e-transfer to avoid double payment.
                *Mandatory Email:{" "}
                <a
                  className="text-primary hover:text-primary-emphasis font-500"
                  href={`mailto:support@icash.ca`}
                >
                  support@icash.ca
                </a>{" "}
                - SMS: (647) 952-6644
              </p>
              <p className="font-400 text-[11px] leading-[16px] mt-[22px] tracking-[-0.1px] text-[#515657]">
                * If you send your payment by e-transfer and the pre-authorized
                debit has already been initiated to debit your bank account, we
                won't be able to stop it. A fee of $5 will be charged to return
                the funds to you. Returning the funds to you. Returning the
                funds will only be done by direct deposit and can take up to 4
                business days.
              </p>
            </div>
          </div>
        </ConfettiModal>
      )}
    </DashboardContainer>
  );
};
